import React, { Component } from "react";

import { Header, Table, Rating, Icon,Button, Input,Label } from "semantic-ui-react";
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

import './style.scss'
class CaseWritingView extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      class:"my-first-class-name"
    };
  }
  componentWillMount(){

  }
  componentDidMount(){

  }
 
  render() {
    return (
        <table style={{width:'100%',minWidth:'768px'}} >
           
  <tbody >
      <tr><td>
      <div className="case-writing-page-view-page">
   
      <div className="case-writing-page-view-page-header">
        <div className="case-writing-page-view-page-header-top-style">   
            </div>
            
        <div className="case-writing-page-view-page-header-content">
       <div className="case-writing-page-view-page-header-content-left">
           <p  style={{fontSize:'20px',fontWeight:'700',marginBottom:'0px'}}>BADER HAMAD AL ZAABI</p>
           <p style={{fontSize:'14px',fontWeight:'700',marginBottom:'0px'}}>ADVOCATES & LEGAL CONSULTANTS</p>
       </div>
       <div className="case-writing-page-view-page-header-content-center">
         <img src={require('../../images/logo/logo2.png')} />
       </div>  
       <div className="case-writing-page-view-page-header-content-right">
           <p className="arabic-font"  style={{fontSize:'50px',fontWeight:'700',marginBottom:'15px',textAlign:'right'}}>بدر حمد الزعابي</p>
           <p className="arabic-font" style={{fontSize:'26px',fontWeight:'700',marginBottom:'0px'}}>للمحاماة والاستشارات القانونية</p>
       </div>  
      </div>    
      </div>
    {/*   {console.log(typeof this.props.data.type != 'object' &&  new DOMParser().parseFromString(), "text/xml"),"ggggggg")}
      */}
     <div>
    {/*  {console.log(draftToHtml(convertToRaw(this.props.data.getCurrentContent())),"fff")}
   */}
     <div style={{paddingRight:'20px',paddingLeft:'20px'}} className="page-break" dangerouslySetInnerHTML={{__html: draftToHtml(convertToRaw(this.props.data.getCurrentContent()))}} />
     </div>

            
      


      <div  className="case-writing-page-view-page-footer">
       <div className="container">
      <div className="row" >
          <div className="col-3 col-lg-3">
             
           <span className="icon-back-letter-pad"><Icon name="phone" /></span> <span style={{fontWeight:'700',fontSize:'12px'}}> +97142555111</span>
          </div>
          <div className="col-3 col-lg-3">
           <span className="icon-back-letter-pad"><Icon name="text telephone" /></span><span style={{fontWeight:'700',fontSize:'12px'}}> +97142368888</span>
          </div>
          <div className="col-3 col-lg-3">
           <span className="icon-back-letter-pad"><Icon name="globe" /></span><span style={{fontWeight:'700',fontSize:'12px'}}> www.legalemirates.com</span>
          </div>
          <div className="col-3 col-lg-3">
           <span className="icon-back-letter-pad"><Icon name="envelope open outline" /></span> <span style={{fontWeight:'700',fontSize:'12px'}}>info@legalemirates.com</span>
          </div>
      </div>
      <div className="row" style={{marginTop:'20px'}}>
      <div className="col-12">
           <span className="icon-back-letter-pad"><Icon name="map marker alternate" /></span><span style={{fontWeight:'700',fontSize:'12px'}}> Office No. 3403, 34th Floor Burj Al Salam Tower, Trade Centre 1, Sheikh Zayed Road, P.O. Box No. 89498, Dubai, UAE.</span>
          </div>  
       {/* <div className="col-12">
       <center><div id="pageFooter-content" >
           
           </div></center>
           </div>  */}  
      </div>
     
      </div>   
      <div className="case-writing-page-view-page-footer-bottom-style">   
            </div>
      </div>
      </div>
      </td></tr></tbody>
  <tfoot><tr><td>
    <div class="footer-space">&nbsp;</div>
  </td></tr></tfoot>
</table>
    );
  }
}
export default CaseWritingView;
