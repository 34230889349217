import React, { Fragment, Component } from 'react';
import { BrowserRouter, Switch, Route,withRouter,Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import {logoutAdmin,
  setCurrentAdmin} from '../../components/adminSignIn/store/actions/codeVerifyAuth'
  import setAuthToken from '../../components/adminSignIn/setAuthToken';
  import jwt_decode from 'jwt-decode';
  import AdminSignIn from '../Pages/adminSignIn'
  import AdminPassReset from '../Pages/adminPasswordReset'
  import AdmineReceipnestFile from '../Pages/filesReceptionist/receipnestFIle'
  import SessionRollAreaPage from '../Pages/sessionRollPage'
  import AddSessionAreaPage from '../Pages/addSessionPage'
  import AllCasesAreaPage from '../Pages/allCasesPage'
   import openFilePage from '../Pages/openFile'
   import AllCasesDetailPage from '../Pages/caseDetailPage'
   import OpenCasePage from '../Pages/openCase'
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import './App.css';
import history from '../../history';
import store from '../../store';
/*    <HeaderTop className="headerTop" /> */
class App extends Component {

  constructor(props, context) {
        super(props, context);
        this.state = {
          authFlag:false,
          adminAuth:false
    } 
   
    store.subscribe(() => {
   

      /* AdminAuthRiderReducer */
      
      let resNumber2 = store.getState().App1.AdminAuthRiderReducer;
      if(resNumber2.isAuthenticated){
          this.setState({adminAuth:true})
    
  }else{
  
    this.setState({adminAuth:false})
  }
    });
    
    
  }

   componentDidMount(){
   

/* logoutAdmin,
setCurrentAdmin
 */
    if(localStorage.receptionistToken) {
   
      const decoded1 = jwt_decode(localStorage.receptionistToken);
   
    
    
      const currentTime = Date.now() / 1000;
      if(decoded1.exp < currentTime) {
        store.dispatch(logoutAdmin());
        window.location.reload('/login');
      }else{
        setAuthToken(localStorage.receptionistToken);
        store.dispatch(setCurrentAdmin(localStorage.getItem('receptionist')));
      }
    }

  } 
    render() {
      /*   window.googleTranslateElementInit(); */
        return (
            <Fragment>
                
                <BrowserRouter history={history}>
                
                    <ToastContainer autoClose={2500} position="top-center" />
                  
                    <Switch>
                      {/* AdmineReceipnest AllCasesDetailPage */}
                      <Route exact path="/login" component={AdminSignIn} />
                      {this.state.adminAuth || localStorage.receptionistToken ? 
                      <>
                      <Route exact path="/" component={openFilePage} />
                      <Route exact path="/createFile" component={openFilePage} />
                      <Route exact path="/allfiles" component={AdmineReceipnestFile} />
                      <Route exact path="/sessions" component={SessionRollAreaPage} />
                      <Route exact path="/addSession" component={AddSessionAreaPage} />
                      <Route exact path="/cases" component={AllCasesAreaPage} />
                      <Route exact path="/opencase" component={OpenCasePage} />
                      <Route exact path="/caseDetail" component={AllCasesDetailPage} />
                      </>
                      :null}
                     
                      <Route exact path="/Adminpassreset" component={AdminPassReset} />
                     
                      <Route exact path="/login" component={AdminSignIn} />
                      <Redirect to="/login" />
                      </Switch>
                    
                </BrowserRouter>
            </Fragment>
        );
    }
}

export default compose(
  withReducer("App1", reducer),
  connect()
)(App);   
 {/* <Route exact path="/founder" component={ComingSoon} /> */}