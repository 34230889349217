import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const UPDATE_CASE_WRITING = "UPDATE_CASE_WRITING";
export const UPDATE_CASE_WRITING_LOADING =
"UPDATE_CASE_WRITING_LOADING";
export const UPDATE_CASE_WRITING_LOADING_FAILED =
"UPDATE_CASE_WRITING_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const updateCaseWritingLoading = ms => ({
    type: UPDATE_CASE_WRITING_LOADING,
    payload: ms
    });
    
    export const updateCaseWritingLoadingFailed = ms => ({
    type: UPDATE_CASE_WRITING_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE 

export function updateCaseWriting(obj) {
        const request = axios.put(
            `https://prod-legalemirates.wowets.com/fileService/receptionistRoutes/api/v1/cases/updateCaseWrittingsByCaseId`,obj, {
                headers: { 'Authorization': 'bearer ' +localStorage.receptionistToken}
              });
   
    
    return dispatch => {

    dispatch(updateCaseWritingLoading());
    request
    .then(response => {   
  
    if (response.status == 200 || response.status == 201) {
     console.log(response,"response updateCaseWriting")
    dispatch({
    type: UPDATE_CASE_WRITING,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        updateCaseWritingLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(updateCaseWritingLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(updateCaseWritingLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(updateCaseWritingLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


