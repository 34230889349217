import React, { Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import FooterArea from '../../../components/FooterArea'

import AllCasesArea from '../../../components/allCases'
import './style.scss'
const AllCasesAreaPage = () => {
    const [readMore, setReadMore] = React.useState(true);
    return (
        <Fragment>
            
           <AllCasesArea
           className="SignInArea"
            />
            <FooterArea/>
        </Fragment>
    )
}
export default AllCasesAreaPage
/* CaseDetailArea */