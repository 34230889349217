import React, { Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import FooterArea from '../../../components/FooterArea'

import OpenCaseArea from '../../../components/openCase'
import './style.scss'
const OpenCasePage = () => {

    return (
        <Fragment>
            
           <OpenCaseArea
           className="SignInArea"
            />
            <FooterArea/>
        </Fragment>
    )
}
export default OpenCasePage
/* OpenCaseArea */