


import React,{useEffect,useState,Component} from 'react'
import { NavLink, Link } from 'react-router-dom'
import './style.scss';
import './style.css';
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import * as Actions from "../adminSignIn/store/actions";
import store from "../../store";
import { Dropdown, Input,Label,Icon,Checkbox } from 'semantic-ui-react'
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery'
import { Menu } from 'antd';
import { AppstoreOutlined, MailOutlined, SettingOutlined ,MenuUnfoldOutlined} from '@ant-design/icons';

const { SubMenu } = Menu;
const MenuCategories=[
    {
        name:'Services',
        icon:<AppstoreOutlined />,
        child:true,
        route:'createFile',
        id:'ServicesReceipnest',
        submenu:[
            {name:'Open a File',route:'createFile',id:"createFileReceipnest"},
            {name:'All Files',route:'allfiles',id:'allfilesReceipnest'},
            {name:'Session Roll',route:'sessions',id:'receptionistsessions'}
            /* {name:'IPAD Service',route:'ipadService',id:'ipadService'}, */
           /*  {name:'Customers',route:'quotationAll',id:"Customer"}, */
           
        ]
    },{
        name:'Cases',
        icon:<MenuUnfoldOutlined />,
        child:true,
        route:'cases',
        id:'casesReceiptist',
        submenu:[
            {name:'Assigned Cases ',route:'cases',id:'AllCasesReceptionist'},
            {name:'Open a Case',route:'opencase',id:'OpenCaseReceptionist'}
        ]
    }
    /* {
        name:'Live Chat',
        icon:'icono izquierda fa fa-commenting-o',
        child:false,
        id:'Chat',
        route:'ReceipnestChat',
        submenu:[]
    } */
    ,
   /*  {
        name:'Purchase',
        icon:"icono izquierda fa fa-cart-arrow-down",
        child:true,
        route:'quotationAll',
        id:'Purchase',
        submenu:[
            {name:'Bills',route:'AcountantChat'},
            {name:'Company/Supplier',route:'quotationAll'},
            {name:'Vendors',route:'quotationAll'},
            {name:'Product & Services',route:'quotationAll'}
           
        ]
    },{
        name:'LPO',
        icon:'icono izquierda fa fa-chrome',
        child:true,
        id:'LPO',
        route:'quotationAll',
        submenu:[]
    },{
        name:'Voucher Receiving',
        icon:'icono izquierda fas fa fa-list-alt',
        child:true,
        id:'VoucherReceiving',
        route:'quotationAll',
        submenu:[]
    },{
        name:'Employ Salary Slip',
        icon:'icono izquierda fa fa-drivers-license-o',
        child:true,
        id:'EmploySalarySlip',
        route:'quotationAll',
        submenu:[]
    },{
        name:'Attendance Sheet',
        icon:'icono izquierda fa fa-file-text',
        child:true,
        id:'AttendanceSheet',
        route:'quotationAll',
        submenu:[]
    },{
        name:'VAT Statement',
        icon:'icono izquierda 	fa fa-eercast',
        child:true,
        id:'VATStatement',
        route:'quotationAll',
        submenu:[]
    } */
]
class  AdminSideBar extends Component{
    rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];
    constructor(props, context) {
        super(props, context);
        this.state = {
            openKeys: ['ServicesReceipnest'],
            defaultSelectedKeys:['createFileReceipnest']
    }
  }


 closeNav=()=>{
  
        document.getElementById("mySidenav3").style.width = "0";
    }
    openNav=()=>{
       
        document.getElementById("mySidenav3").style.width = "260px";  
    }
    onOpenChange = openKeys => {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            /* console.log(openKeys,"gggggggggggg") */
            localStorage.setItem('openKeys',openKeys[openKeys.length-1])
          this.setState({ openKeys:openKeys });
        } else {
          this.setState({
            openKeys: latestOpenKey ? [latestOpenKey] : [],
          });
        }
      };
      componentWillMount(){
        if(localStorage.openKeys){
this.setState({openKeys:[localStorage.openKeys]})
        }
        if(localStorage.defaultSelectedKeys){
            this.setState({defaultSelectedKeys:[localStorage.defaultSelectedKeys]})
                    }
      }
      handleLink=(id)=>{
          localStorage.setItem('defaultSelectedKeys',id)
      }
    render(){
       /*  console.log(this.state.openKeys,"jjjjjjjj") */
        return (
            <div style={{borderLeft:'1px solid lightgray',height:'100%'}}>
       
            <div id="mySidenav3" className="sidenav" >
            <div classNameName="admin-side-bar-area sidenav" style={{backgroundColor:'white'}}>
    
            <div className="admin-profile-area-acountant">
    
    <Label as='a' style={{fontSize:'20px',transform:'rotate(180deg)',marginBottom:'15px',marginLeft:'0px'}} /* color='teal' */ tag>
    <div style={{fontSize:'14px',transform:'rotate(180deg)'}}>Receptionist Panel</div>
    </Label>
    <h3 className="sidebarTitle" >  <a href="javascript:void(0)" className="closebtn" onClick={this.closeNav}>&times;</a></h3>
    <Menu
        mode="inline"
        openKeys={this.state.openKeys}
        onOpenChange={this.onOpenChange}
        style={{ width: '100%' }}
        defaultSelectedKeys={this.state.defaultSelectedKeys}
      >
          {MenuCategories.map((item)=>{
              return  <SubMenu
              key={item.id}
              title={
                <span>
                <span style={{marginTop:'-3px'}}>  {item.icon}</span>
              <span>{item.name}</span>
                </span>
              }
            >
            {item.submenu.map((item1)=>{
               return <Menu.Item onClick={()=>{this.handleLink(item1.id)}} key={item1.id}> <Link to={item1.route}>{item1.name}</Link></Menu.Item>
            })}
              
            </SubMenu>
          })}
       
        
      </Menu>
    </div>
   
            </div>
            </div>
    <div style={{marginBottom:'0px',marginTop:'10px',marginLeft:'30px',backgroundColor:'white',padding:'4px'}} >
    <span className="sidenav4" style={{fontSize:'25px',cursor:'pointer'}} onClick={this.openNav}>&#9776; Receptionist Panel</span>
    </div>
            </div>
        )
    }
    
}
export default  withReducer("AdminSideBar", reducer)
  (AdminSideBar); 