import React, { useEffect, useState, Component } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";
import "../sideBar/style.scss";
import "../sideBar/style.css";
import AvatarEditor from "react-avatar-editor";
import StarRatings from "react-star-ratings";
import {
  Form,
  ButtonToolbar,
 
  Modal,
  FormGroup,
  FormControl,
  Col,
  Row,
  ProgressBar,
  Container,
} from "react-bootstrap";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { Header, Table, Rating } from "semantic-ui-react";
import {  Placeholder, Popup,Icon, Card, Image ,Button } from 'semantic-ui-react'
import {
  Input,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  Cascader,
} from "antd";

import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import "antd/dist/antd.css";
import store from "../../store";
import { useDispatch, useSelector } from "react-redux";
var moment = require("moment");
const todayStart=moment().startOf('day').toISOString()
const todayEnd=moment().endOf('day').toISOString()
class SessionRoll extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      sessionData: [],
      limit: 10,
      pageNo: 1,
      userId: undefined,
      totalPages: 11,
      pageNumber: 1,
      options: [],
      direction: null,
      column: null,
      startDate:todayStart,
      endDate:todayEnd,
      checkFilter:'day',
      data:null,
      timer:''
    };
  }
  componentWillMount() {
    let obj = {
      startDate:this.state.startDate,
      endDate:this.state.endDate,
      limit: this.state.limit,
      pageNo: this.state.pageNo
    };
    this.props.dispatch(Actions.getSessions(obj));
  }

  handleLimit = (e) => {
    this.setState({ limit: e.target.value, sessionData: [] });
    let obj = {
      startDate:this.state.startDate,
      endDate:this.state.endDate,
      limit: e.target.value,
      pageNo: this.state.pageNo,
    };
    this.props.dispatch(Actions.getSessions(obj));
  };
  handleJumpPage = (e) => {
    this.setState({ pageNumber: e.target.value, sessionData: [] });
    let obj = {
      startDate:this.state.startDate,
      endDate:this.state.endDate,
      limit: this.state.limit,
      pageNo: e.target.value,
    };
    this.props.dispatch(Actions.getSessions(obj));
  };
  handlePageInc = () => {
    if (this.state.totalPages > this.state.pageNumber + 1) {
      this.setState((prevState, props) => ({
        pageNumber: prevState.pageNumber + 1,
      }));
      this.setState({ sessionData: [] });
      let obj = {
        startDate:this.state.startDate,
      endDate:this.state.endDate,
        limit: this.state.limit,
        pageNo: this.state.pageNumber + 1,
      };
      this.props.dispatch(Actions.getSessions(obj));
    }
  };
  handlePageDec = () => {
    if (this.state.pageNumber > 1) {
      this.setState({ sessionData: [] });
      this.setState((prevState, props) => ({
        pageNumber: prevState.pageNumber - 1,
      }));
      let obj = {
        startDate:this.state.startDate,
      endDate:this.state.endDate,
        limit: this.state.limit,
        pageNo: this.state.pageNumber - 1,
      };
      this.props.dispatch(Actions.getSessions(obj));
    }
  };
  componentWillReceiveProps(nextProps) {
    let resNumber1 = nextProps.getCusomterSessionReducer;

    if (!resNumber1.isLoading) {
      if (resNumber1.errMsg == null && !(resNumber1.data === undefined)) {
        if (resNumber1.data.caseSessions.docs.length > 0) {
          this.setState({
            sessionData: resNumber1.data.caseSessions.docs,
            totalPages: resNumber1.data.caseSessions.totalPages,
          });
       
        } else {
          this.setState({ sessionData: ["nothing"] });
        }
      } else {
        this.setState({
          loaderFlag: false,
          errFlag: true,
          errMessage: resNumber1.errMsg,
        });
      }
    }
  }
  handleFilterRang=(e)=>{
this.setState({startDate:e[0]._d.toISOString(),endDate:e[1]._d.toISOString(),sessionData:[]})
let obj = {
  startDate:e[0]._d.toISOString(),
  endDate:e[1]._d.toISOString(),
  limit: this.state.limit,
  pageNo: this.state.pageNo
};
this.props.dispatch(Actions.getSessions(obj));

  }
  handleCurrentWeek=()=>{
    let s=moment().startOf('week').toISOString()
    let e=moment().endOf('week').toISOString()
    this.setState({startDate:s,endDate:s,sessionData:[],checkFilter:'week'})
    let obj = {
      startDate:s,
      endDate:e,
      limit: this.state.limit,
      pageNo: this.state.pageNo
    };
    
    this.props.dispatch(Actions.getSessions(obj));
    
  }
  handleCurrentDay=()=>{
    let s=moment().startOf('day').toISOString()
    let e=moment().endOf('day').toISOString()
    this.setState({startDate:s,endDate:s,sessionData:[],checkFilter:'day'})
    let obj = {
      startDate:s,
      endDate:e,
      limit: this.state.limit,
      pageNo: this.state.pageNo
    };
   
    this.props.dispatch(Actions.getSessions(obj));
    
  }
  handleCurrentMonth=()=>{
    let s=moment().startOf('month').toISOString()
    let e=moment().endOf('month').toISOString()
    this.setState({startDate:s,endDate:s,sessionData:[],checkFilter:'month'})
    let obj = {
      startDate:s,
      endDate:e,
      limit: this.state.limit,
      pageNo: this.state.pageNo
    };
    
    this.props.dispatch(Actions.getSessions(obj));
    
  }
  handleCurrentYear=()=>{
    let s=moment().startOf('year').toISOString()
    let e=moment().endOf('year').toISOString()
    this.setState({startDate:s,endDate:s,sessionData:[],checkFilter:'year'})
    let obj = {
      startDate:s,
      endDate:e,
      limit: this.state.limit,
      pageNo: this.state.pageNo
    };

    this.props.dispatch(Actions.getSessions(obj));
    
  }
  render() {
    return (
      <div className="admin-cutomer-profile-area">
        <div className="receipnest-file-customer-files-area">
          <div
            className="receipnest-file-customer-files-area6551"
            style={{ paddingBottom: "400px" }}
          >
            <div className="receptionist-session-top-area">
              <div className="receptionist-session-top-area-left">
                <div onClick={this.handleCurrentDay} className={this.state.checkFilter==='day'?'reception-session-filter-general-buttons-active':'reception-session-filter-general-buttons'}>
                  Day
                </div>
                <div onClick={this.handleCurrentWeek} className={this.state.checkFilter==='week'?'reception-session-filter-general-buttons-active':'reception-session-filter-general-buttons'}>
                  Week
                </div>
                <div onClick={this.handleCurrentMonth} className={this.state.checkFilter==='month'?'reception-session-filter-general-buttons-active':'reception-session-filter-general-buttons'}>
                  Month
                </div>
                <div onClick={this.handleCurrentYear} className={this.state.checkFilter==='year'?'reception-session-filter-general-buttons-active':'reception-session-filter-general-buttons'}>
                  Year
                </div>
              </div>
              <div className="receptionist-session-top-area-right">
              <div
                  className="reception-session-filter-general-buttons"
                 
                >
               <a href="https://www.dc.gov.ae/PublicServices/SessionAgenda.aspx?lang=ar-AE" target="_blank" style={{color:'white'}}>  View  from official site</a>
                </div>
                <div
                  className="reception-session-filter-general-buttons"
                  onClick={() => this.props.history.push("/addSession")}
                >
                  <i className="fa fa-plus"></i> Add Session
                </div>
              </div>
            </div>
            <div className="session-roll-table-top-area">
              <div className="session-roll-table-top-area-left">
                Session Roll
              </div>
              <div className="session-roll-table-top-area-right">
                <Input.Group compact>
                  <DatePicker.RangePicker onChange={this.handleFilterRang} style={{ width: "80%" }} />

                  <Input
                    style={{ width: "20%" }}
                    type="submit"
                    defaultValue="Filter"
                  />
                </Input.Group>
              </div>
            </div>
            <div className="session-table-area-receptionist">
              <Table celled padded sortable>
                <Table.Header style={{ fontSize: "12px" }}>
                  <Table.Row>
                    <Table.HeaderCell singleLine>
                      <center>
                        <span
                          className="arabic-style-font-visitor"
                          style={{ fontSize: "18px" }}
                        >
                          صاحب القرار
                        </span>
                        <br />
                        Decision Maker
                      </center>
                    </Table.HeaderCell>
                    <Table.HeaderCell singleLine>
                      <center>
                        <span
                          className="arabic-style-font-visitor"
                          style={{ fontSize: "18px" }}
                        >
                          الشخص المتابع للقضية
                        </span>
                        <br />
                        Case Followers
                      </center>
                    </Table.HeaderCell>
                    <Table.HeaderCell singleLine>
                      <center>
                        <span
                          className="arabic-style-font-visitor"
                          style={{ fontSize: "18px" }}
                        >
                          تاريخ الجلسة القادمة القرار
                        </span>
                        <br />
                        Next Session Decision
                      </center>
                    </Table.HeaderCell>
                    <Table.HeaderCell singleLine>
                      <center>
                        <span
                          className="arabic-style-font-visitor"
                          style={{ fontSize: "18px" }}
                        >
                          الطلبات
                        </span>
                        <br />
                        Requests
                      </center>
                    </Table.HeaderCell>
                    <Table.HeaderCell singleLine>
                      <center>
                        <span
                          className="arabic-style-font-visitor"
                          style={{ fontSize: "18px" }}
                        >
                          قرار الجلسة السابقة
                        </span>
                        <br />
                        Previous Session Decision
                      </center>
                    </Table.HeaderCell>
                    <Table.HeaderCell singleLine>
                      <center>
                        <span
                          className="arabic-style-font-visitor"
                          style={{ fontSize: "18px" }}
                        >
                          اسم الخصم وصفته
                        </span>
                        <br />
                        Defendant Name/
                        <br />
                        Type
                      </center>
                    </Table.HeaderCell>
                    <Table.HeaderCell singleLine>
                      <center>
                        <span
                          className="arabic-style-font-visitor"
                          style={{ fontSize: "18px" }}
                        >
                          اسم الموكل وصفته
                        </span>
                        <br />
                        Client Name/
                        <br />
                        Type
                      </center>
                    </Table.HeaderCell>
                    <Table.HeaderCell singleLine>
                      <center>
                        <span
                          className="arabic-style-font-visitor"
                          style={{ fontSize: "18px" }}
                        >
                          رقم الدعوى ونوعها
                        </span>
                        <br />
                        Case Number/Type
                      </center>
                    </Table.HeaderCell>
                    <Table.HeaderCell singleLine>
                      <center>
                        <span
                          className="arabic-style-font-visitor"
                          style={{ fontSize: "18px" }}
                        >
                          مكان الجلسة الدائرة/الوقت
                        </span>
                        <br />
                        Location/Time
                      </center>
                    </Table.HeaderCell>
                    <Table.HeaderCell singleLine>
                      <center>
                        <span
                          className="arabic-style-font-visitor"
                          style={{ fontSize: "18px" }}
                        >
                          رقم الملف
                        </span>
                        <br />
                        File No.
                      </center>
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      <center>
                        <span
                          className="arabic-style-font-visitor"
                          style={{ fontSize: "18px" }}
                        >
                          م
                        </span>
                        <br />
                        Serial No.
                      </center>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body style={{ fontSize: "12px", fontWeight: "550" }}>
                  {this.state.sessionData.length > 0 ? (
                    this.state.sessionData[0] != "nothing" ? (
                      this.state.sessionData.map((item,index) => {
                        return (
                          <Table.Row>
                            <Table.Cell textAlign="center">
                           
                              <Popup
      on='click'
      onClose={() => {
        this.setState({data:null})
      }}
      onOpen={() => {
        this.setState({data:'dddd'})

      }}

      popperDependencies={[!!this.state.data]}
      trigger={<Button style={{backgroundColor:'lightgrey',color:'#666666'}}   > Decision detail </Button>}
      wide
      position='top left'
    >
      {this.state.data === null ? (
        <Placeholder style={{ minWidth: '200px' }}>
          <Placeholder.Header>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line length='medium' />
            <Placeholder.Line length='short' />
          </Placeholder.Paragraph>
        </Placeholder>
      ) : (
        <>
         <Card>
      <Card.Content>
        <Image
          floated='right'
          size='mini'
          src={require('../../images/profiled1.png')}
        />
        
        <Card.Header> 
        
                        Decision Maker
                        <br />
                        <span
                          className="arabic-style-font-visitor"
                          style={{ fontSize: "18px" }}
                        >
                          صاحب القرار
                        </span>
                      
                     </Card.Header>
       <Card.Description>
        {item.decisionMaker}
      
       </Card.Description>
      </Card.Content>
      
    </Card>
        </>
      )}
    </Popup>

                            </Table.Cell>
                            <Table.Cell textAlign="center" singleLine>
                            {item.caseFollowers[0]}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                             {moment(item.prevSessionDate).format('MMMM Do YYYY, h:mm:ss a')} <br />
                              {item.nextSessionDecision}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                            {item.request}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                            {moment(item.nextSessionDate).format('MMMM Do YYYY, h:mm:ss a')} <br />
                              {item.prevSessionDecision}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                            {item.defendantName}<br />
                            {item.defendantType}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                            {item.clientName} <br />
                            {item.clientType}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              BZ/{item.caseNo} <br />
                              {item.caseType}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                            {item.locOfSession} in the Room No 12 <br />
                              {moment(item.scheduleTime).format('MMMM Do YYYY, h:mm:ss a')}
                            </Table.Cell>
                            <Table.Cell textAlign="center">BZ/{item.fileNo}</Table.Cell>
                        <Table.Cell textAlign="center">{index+1}</Table.Cell>
                          </Table.Row>
                        );
                      })
                    ) : (
                      <Table.Row>
                        <Table.Cell colSpan="11" textAlign="center">
                          <center>
                            <div
                              className="loader-for-tabel-invoce"
                              style={{ fontWeight: "550" }}
                            >
                              No Session Exist Yet!
                            </div>
                          </center>
                        </Table.Cell>
                      </Table.Row>
                    )
                  ) : (
                    <Table.Row>
                      <Table.Cell
                        textAlign="center"
                        colSpan="11"
                        textAlign="center"
                      >
                        <center>
                          <div className="loader-for-tabel-invoce">
                            <div className="loader1188"></div>
                          </div>
                        </center>
                      </Table.Cell>
                    </Table.Row>
                  )}
                
                </Table.Body>
              </Table>
            </div>
            <div className="quotaion-table-pagination-area">
              <div style={{ marginTop: "10px" }}>
                show{" "}
                <select onChange={this.handleLimit}>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>{" "}
                per page
              </div>
              <div style={{ marginTop: "10px" }}>
                <span style={{ marginRight: "15px" }}>
                  Page {this.state.pageNumber} of {this.state.totalPages}{" "}
                  <span className="pagination-table-quotaion-dec">
                    <i
                      title="Previous Page"
                      onClick={this.handlePageDec}
                      className="fa fa-caret-left"
                    ></i>
                  </span>
                  <span className="pagination-table-quotaion-inc">
                    <i
                      onClick={this.handlePageInc}
                      title="Next Page"
                      className="fa fa-caret-right"
                    ></i>
                  </span>
                </span>
                jump to page{" "}
                <select onChange={this.handleJumpPage}>
                  {Array.from(
                    { length: this.state.totalPages },
                    (x, i) => i
                  ).map((item, index) => {
                    return <option value={index + 1}>{index + 1}</option>;
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getCusomterSessionReducer: state.SessionRoll1.getCusomterSessionReducer,
    fileSearchReducer: state.SessionRoll1.fileSearchReducer,
  };
};
export default compose(
  withReducer("SessionRoll1", reducer),
  connect(mapStateToProps),
  withRouter
)(SessionRoll);
