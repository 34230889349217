import React, { useEffect, useState, Component } from "react";
import { Header, Table, Rating } from 'semantic-ui-react'
import {Recorder} from 'react-voice-recorder'
import 'react-voice-recorder/dist/index.css'
import { NavLink, Link, withRouter } from "react-router-dom";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import { notification } from 'antd'
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";

import {Modal} from 'react-bootstrap';


var moment = require('moment');
class AddAudioCase extends Component {
  constructor(props) {
    super(props);
    this.state = {
        flag:false,
        flag1:false,
        show:false,
        audioDetails:{
            url: null,
            blob: null,
            chunks: null,
            duration: {
            h: null,
            m: null,
            s: null,
            }
            },
            
    };
  }
  componentDidMount(){
    /* ._2gd2_ */
    document.getElementsByClassName("_1Pz2d")[0].style.display = "none";
    document.getElementsByClassName("_2gd2_")[0].style.display = "none";
  }
  handleAudioStop=(data)=>{
    console.log(data)
    this.setState({ audioDetails: data,flag:true });

    document.getElementsByClassName("_1Pz2d")[0].style.display = "inline";
    document.getElementsByClassName("_2gd2_")[0].style.display = "inline";

    document.getElementsByClassName("_f2DT8")[0].style.display = "none";
/*     document.getElementsByClassName("_3bC73")[0].style.display = "none"; */
console.log(document.getElementsByClassName("_3bC73"),"ggggggggggg")
 
   setTimeout(()=>{ 
    document.getElementsByClassName("_1dpop")[0].style.display = "none";   
   },500)
    /* class="_1dpop md-opjjpmhoiojifppkkcdabiobhakljdgm_doc"
    _1ceqH ._1dpop _1ceqH
    class="_qxztz"
    */
    }
    handleAudioUpload=(file) =>{
      if(this.state.flag){
    this.setState({show:true,flag1:true})
    const  Data=  new FormData();
   let audio=new File([file], "uploaded_file.mp3", { type: "audio/ogg", lastModified: Date.now() })

      Data.append('caseId',this.props.caseDetail._id) 
      Data.append('audioNote',audio) 
      this.props.dispatch(Actions.addAudioNOte(Data))
    setTimeout(()=>{
      this.props.dispatch(Actions.getCaseDetail(localStorage.caseId))
    },1000)
    setTimeout(()=>{
      this.props.dispatch(Actions.getCaseDetail(localStorage.caseId))
    },2000)
    setTimeout(()=>{
      this.props.dispatch(Actions.getCaseDetail(localStorage.caseId))
    },3000)
    }
    }
  
    handleRest=()=>{
   
       if(this.state.flag){
    const reset = {
    url: null,
    blob: null,
    chunks: null,
    duration: {
    h: null,
    m: null,
    s: null,
    }
    }
    this.setState({ audioDetails: reset,flag:false });
    document.getElementsByClassName("_1Pz2d")[0].style.display = "none";
    document.getElementsByClassName("_2gd2_")[0].style.display = "none";
    document.getElementsByClassName("_f2DT8")[0].style.display = "block";
    document.getElementsByClassName("_1dpop")[0].style.display = "block";  
    document.getElementsByClassName("_1dpop")[0].style.display = "flex"; 
    document.getElementsByClassName("_1dpop")[0].style.alignItem = "center"; 
    document.getElementsByClassName("_1dpop")[0].style.justifyContent = "center";
  }
    }
    componentWillReceiveProps(nextProps) {
    let resNumber7 = nextProps.addAudioNoteREd;

    if (!resNumber7.isLoading) {
      if (resNumber7.errMsg == null && !(resNumber7.data === undefined)) {
        if(this.state.flag1){
        this.setState({show:false,flag1:false})
        this.handleRest()
      /*   console.log(resNumber7.data.updatedCase,"gggggggggg") */
       /*  this.setState({caseDetail:resNumber1.data.updatedCase}) */
       window.scrollTo({top:0, behavior: 'smooth'})
        notification.open({
          message: 'Audio Upload Successfully',
          description:
            'Your Recording is successfully Saved!',
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      }
      } else {
      
      }
    }
  }
  render() {
    return (
        <div><Table celled padded style={{zIndex:'10000'}}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center" singleLine>Sr No</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Upload  At</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Audio</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
    
        <Table.Body>
          {this.props.caseDetail.audioNotes && 
          this.props.caseDetail.audioNotes.length>0 ?this.props.caseDetail.audioNotes.map((item,index)=>{
            return  <Table.Row>
            <Table.Cell verticalAlign="middle" textAlign="center">
                {index+1}

            </Table.Cell>
          <Table.Cell verticalAlign="middle" textAlign="center" singleLine>{moment(item.createdAt).format('MMMM Do YYYY,h:mm:ss a')}</Table.Cell>
            <Table.Cell textAlign="center">
            <audio controls>
  <source src={item.audioPath} type="audio/ogg" />
  <source src={item.audioPath} type="audio/mpeg" />
Your browser does not support the audio element.
</audio>
            </Table.Cell>
           
          </Table.Row>
          }): <Table.Row>
          <Table.Cell colSpan="3" textAlign="center">
            <center>
              <div
                className="loader-for-tabel-invoce"
                style={{ fontWeight: "550" }}
              >
                No Recording  Exist Yet!
              </div>
            </center>
          </Table.Cell>
        </Table.Row>}
         
          </Table.Body>
          </Table>
      <div style={{marginTop:'0px'}}>
        {/* <div className="row" style={{marginBottom:'-250px'}}>
        <div className="col-12 col-md-3"></div>
            <div className="col-12 col-md-6">
              <div className="formInput" >
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "white",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
                   Audio Title*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "white",
                  }}>
العنوان الصوتي
                </span>
                </div>
                <input
                  placeholder=""
                  dir={this.state.typingArabic?"rtl":"ltr"}
                  name="fname"
                  className="form-control"
                  type="text"
                  style={{zIndex:'1000000'}}
                />

               
              </div>
            </div>
            <div className="col-12 col-md-3"></div>
            </div> */}
       
      <div style={{marginTop:'40px'}}>
      <Recorder
record={true}
title={"Add New recording"}
audioURL={this.state.audioDetails.url}
showUIAudio={true}
handleAudioStop={data => this.handleAudioStop(data)}

handleAudioUpload={data => this.handleAudioUpload(data)}
handleRest={() => this.state.flag? this.handleRest():''} />
      </div>
      </div>
      <Modal show={this.state.show} onHide={()=>{this.setState({show:false})}} size="sm" centered  >
        
        <Modal.Body>
           <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           <center>
               <div className="loader1188"></div>
               <p style={{paddingTop:'20px'}}>uploading Please wait....</p>
           </center>
          </div>
          
        </Modal.Body>
    
      </Modal> 
      
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    addAudioNoteREd: state.AddAudioCase1.addAudioNoteREd,
  };
};
export default compose(
  withReducer("AddAudioCase1", reducer),
  connect(mapStateToProps),
  withRouter
)(AddAudioCase);
