import React, { Component } from 'react';
import { EditorState, convertToRaw,convertFromRaw,ContentState  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Header, Table, Rating, Icon,Button, Input,Label } from "semantic-ui-react";
import CaseWritingView from './caseWritingView'
import './style.scss'
import { notification } from 'antd'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { NavLink, Link, withRouter } from "react-router-dom";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";

class CaseWriting extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            editorState: EditorState.createEmpty(),
            loaderFlag:false,
            caseDetail:{},
            updateCaseWritingErrorFlag:false,
            updateCaseWritingFlag:false,
            caseGetFlag:true
        };
        this.ref = React.createRef();
      }
 

  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
  };
  componentWillMount() {
    /* getCaseDetail */
    this.props.dispatch(Actions.getCaseDetail(localStorage.caseId))
  }
componentWillReceiveProps(nextProps) {
    let resNumber1 = nextProps.updateCaseWritingReducer;

    if (!resNumber1.isLoading) {
      if (resNumber1.errMsg == null && !(resNumber1.data === undefined)) {
          if(this.state.updateCaseWritingFlag){
              this.setState({updateCaseWritingFlag:false,loaderFlag:false,updateCaseWritingErrorFlag:false,caseDetail:resNumber1.data.case})
          /*   console.log(resNumber1.data,"ggggggggggg") */
         
            notification.open({
             message: 'Case Notification',
             description:
               'The case has been succesfully updated and saved!',
             onClick: () => {
               console.log('Notification Clicked!');
             },
           });
          }
   
 
      } else {
      if(this.state.updateCaseWritingErrorFlag){
        this.setState({updateCaseWritingErrorFlag:false,loaderFlag:false,updateCaseWritingFlag:false})
      
        notification.open({
         message: 'Updation Error',
         description:resNumber1.errMsg,
         onClick: () => {
           console.log('Notification Clicked!');
         },
       });
      }
      }
    }
    let resNumber2 = nextProps.getCaseDetailReducer;

    if (!resNumber2.isLoading) {
      if (resNumber2.errMsg == null && !(resNumber2.data === undefined)) {
          if(this.state.caseGetFlag){
            this.setState({caseDetail:resNumber2.data.caseDetail,caseGetFlag:false})
            if(resNumber2.data.caseDetail.caseWrittings.length>0){

                let blocksFromHtml=htmlToDraft(resNumber2.data.caseDetail.caseWrittings[0])
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorState = EditorState.createWithContent(contentState);
                   this.setState({editorState:editorState})
               /*  let blocks=htmlToDraft(resNumber2.data.caseDetail.caseWrittings[0]).contentBlocks
                let entity=htmlToDraft(resNumber2.data.caseDetail.caseWrittings[0]).entityMap
                 let obj={
                    "entityMap":entity,
                    "blocks":blocks
                 }  
                 console.log(convertFromRaw(obj),"hphphphphphphph") */
          
                /* contentBlocks */
            }
            
          }
      
 
      } else {
      
      }
    }
}
handleSaveWriting=()=>{
this.setState({loaderFlag:true,updateCaseWritingFlag:true,updateCaseWritingErrorFlag:true})

/* this.props.caseDetail._id */
let data=draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
let obj={
    caseId:this.state.caseDetail._id,
    caseWrittings: [data]
}
this.props.dispatch(Actions.updateCaseWriting(obj))

}
/*  */
/* updateCaseWritingReducer
updateCaseWriting
*/
  render() {
    const { editorState } = this.state;
   /*  console.log(this.state.caseDetail,"hhhhhhhhhhhh") */
   
    return (
        <div className="case-writing-page-area">
      <div className="case-writing-editor-area">
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
      
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="button-of-case-writing-arae">
        <span style={{ width: "50px", height: "40px",marginRight:'15px' }}>
                          {this.state.loaderFlag ? (
                            <div
                              style={{ marginRight: "10px" }}
                              className="loader"
                            ></div>
                          ) : (
                            ""
                          )}
                        </span>{" "}
        <Button.Group>
    <Button>   <ReactToPrint
                                      removeAfterPrint={true}
                                      trigger={() => {
                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                        // to the root node of the returned component as it will be overwritten.
                                        return <div> Print Case Writing</div>;
                                      }}
                                      content={() => this.componentRef}
                                      documentTitle="Legal Emirates Case Writing"
                                    /></Button>
    <Button.Or />
    <Button onClick={this.handleSaveWriting} color="black">Save</Button>
  </Button.Group>
        </div>
       {/*    <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        /> */}
      </div>
      {/* <center>  <Button
                                    className="le-newinvoice-payment-voucher-edit-button"
                                    variant="outline-grey" style={{marginTop:'30px',marginBottom:'30px'}}
                                  >
                                    <ReactToPrint
                                      removeAfterPrint={true}
                                      trigger={() => {
                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                        // to the root node of the returned component as it will be overwritten.
                                        return <div> Print Case Writing</div>;
                                      }}
                                      content={() => this.componentRef}
                                      documentTitle="Legal Emirates Case Writing"
                                    />
                                  </Button></center> */}
                                  <div style={{ overflowX: "auto",overflowY:'hidden',marginTop:'30px' }}>    
                <CaseWritingView data={this.state.editorState} ref={(el) => (this.componentRef = el)} />
               </div>
     
      </div>
    );
  }
}
const mapStateToProps = (state) => {
    return {
        updateCaseWritingReducer: state.CaseWriting1.updateCaseWritingReducer,
        getCaseDetailReducer:state.CaseWriting1.getCaseDetailReducer
    };
  };
export default compose(
    withReducer("CaseWriting1", reducer),
    connect(mapStateToProps),
    withRouter
  )(CaseWriting);