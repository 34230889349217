import React, { Component } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { NavLink, Link,withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./switch.css";
import { DatePicker, Space } from 'antd';
import 'antd/dist/antd.css';
/* import "./style.scss"; */
import SignatureCanvas from "react-signature-canvas";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import { Dropdown, Input,Label,Icon,Checkbox,TextArea,Header, Feed } from 'semantic-ui-react'

import "./formStyle.scss";
import {
  Button,
  Modal,
  FormGroup,
  FormControl,
  Accordion,
  Col,
  Row,
  Card,
  InputGroup,
  Container,
} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();
class AddSessionForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      fname: "",
      email: "",
      description: "",
      LawyerName: "Bader Al Zaabi",
      termsConditions: false,
      termsSubmitFlag: false,
      error: {},
      type: "password",
      passFlag: false,
      date88: "text",
      date99: "text",
      termsFlag: true,
      trimmedDataURL: null,
      paymentInvoice:false,
      captcha:null,
      buttonFlag:false,
      registrationFlag:true,
      show: false,
      cardType:'',
      loaderFlag:false,
      fileOptions:["FILE_REG"],
      ConsultationFlag:false,
      attorneyFlag:false,
      behalfFlag:false,
      CorporateFlag:false,
      BankFlag:false,
      IndividualFlag:false,
      errMessage:'',
      errMsgUser:'',
      customers:[],
      addCustomerFlag:true,
      searchFlag:false,
      key:'',
      addedCustomer:{},
      customerAdded:false,
      userId:'',
      fileNo:1,
      typingArabic:false,
      fileNumber:'',
      LocatioSession:'',
      CourtRoom:'',
      CaseNo:'',
      CaseType:'',
      ClientType:'',
      DefendantName:'',
      DefendantType:'',
      PreviousSessionDecision:'',
      NextSessionDecision:'',
      DecisionMaker:'',
      CaseFollowers:'',
      locationTime:'',
      previousTime:'',
      nextTime:'',
      submitFlag:false,
      options1:[],
      fileLoading:false,
      fileError:false,
      fileError1:'',
      getFileFlag:false,
      adminSignFlag:false
    };
   
  }
  handleClose=()=> {
    this.setState({ show: false });
    this.props.history.push('/sessions')
    window.scrollTo({top:0, behavior: 'smooth'})
  }

  handleShow=()=> {
    this.setState({ show: true });
  }

  schema = {
    fname: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Full Name can not be Empty";
              break;
          }
        });
        return errors;
      }),
      fileNumber:Joi.string().required().error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                default:
                    err.message = 'select the file No first';
                    break;
            }
        });
        return errors;
    }),
  
    LocatioSession: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Location Session can not be Empty";
              break;
          }
        });
        return errors;
      }),
  
      CourtRoom: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Court Room can not be Empty";
              break;
          }
        });
        return errors;
      }),



    description: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Summary Of Matter can not be Empty ";
              break;
          }
        });
        return errors;
      }),
      CaseNo: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Case No  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
 
    CaseType: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Case Type  must be select ";
              break;
          }
        });
        return errors;
      }),
    
    ClientType: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Client Type  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
       
    DefendantName: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "DefendantName  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
      
    DefendantType: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Defendant Type  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
      
    PreviousSessionDecision: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Previous Session Decision  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    NextSessionDecision: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Next Session Decision  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
     
    DecisionMaker: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Decision Maker can not be Empty ";
              break;
          }
        });
        return errors;
      }),
      
    CaseFollowers: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Case Followers  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
      /*
    ProfessionalFee1: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Professional Fee  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    Max: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Max Fee  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    OfficialFee55: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Official Fee  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    Administrativefees: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Administrative Fee  can not be Empty ";
              break;
          }
        });
        return errors;
      }), */

 

    /* case: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Case Type must be select ";
              break;
          }
        });
        return errors;
      }), */
  };
  changeHandler = (event) => {
    const error = { ...this.state.error };
    const errorMassage = this.validationProperty(event);
    if (errorMassage) {
      error[event.target.name] = errorMassage;
    } else {
      delete error[event.target.name];
    }
    this.setState({
      [event.target.name]: event.target.value,
      error,
    });
  };
  handleChange = (value) => {
    this.setState({
      country: value,
    });
  };

  validationProperty = (event) => {
    const Obj = { [event.target.name]: event.target.value };
    const schema = { [event.target.name]: this.schema[event.target.name] };
    const { error } = Joi.validate(Obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const form = {
      fname: this.state.fname,
      description: this.state.description,
      fileNumber:this.state.fileNumber,
      LocatioSession:this.state.LocatioSession,
      CourtRoom:this.state.CourtRoom,
      CaseNo:this.state.CaseNo,
      CaseType:this.state.CaseType,
      ClientType:this.state.ClientType,
      DefendantType:this.state.DefendantType,
      DefendantName:this.state.DefendantName,
      PreviousSessionDecision:this.state.PreviousSessionDecision,
      NextSessionDecision:this.state.NextSessionDecision,
      DecisionMaker:this.state.DecisionMaker,
      CaseFollowers:this.state.CaseFollowers
      /* AmountClaim: this.state.AmountClaim,
      ProfessionalFees: this.state.ProfessionalFees,
      InAdvance: this.state.InAdvance,
      FirstInstance: this.state.FirstInstance,
      Appeal: this.state.Appeal,
      CourtFee: this.state.CourtFee,
      Cassation: this.state.Cassation,
      Execution: this.state.Execution,
      ProfessionalFee1: this.state.ProfessionalFee1,
      Max: this.state.Max,
      OfficialFee55: this.state.OfficialFee55,
      Administrativefees: this.state.Administrativefees, 

      case: this.state.case,*/
    };
    const { error } = Joi.validate(form, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  submitHandler = (event) => {
    event.preventDefault();
    const error = this.validate();
    this.setState({submitFlag:true})
    if (error) {
      this.setState({
        error: error || {},
      });
    } else {
        if (this.state.locationTime.length>0 && this.state.previousTime.length>0 && this.state.nextTime.length>0) {
       
    this.setState({adminSignFlag:true,loaderFlag:true})

             /*addSession*/  
           /* addSessionReducer 
           trimmedDataURL
           */
           
             let obj={
                userId:this.state.userId,
                fileNo:this.state.fileNumber,
                locOfSession:this.state.LocatioSession,
                courtRoom:this.state.CourtRoom,
                scheduleTime:this.state.locationTime,
                caseNo:this.state.CaseNo,
                caseType:this.state.CaseType,
                clientName:this.state.fname,
                clientType:this.state.ClientType,
                defendantName:this.state.DefendantName,
                defendantType:this.state.DefendantType,
                prevSessionDecision:this.state.PreviousSessionDecision,
                prevSessionDate:this.state.previousTime,
                nextSessionDecision:this.state.NextSessionDecision,
                nextSessionDate:this.state.nextTime,
                decisionMaker:this.state.DecisionMaker,
                caseFollowers:[this.state.CaseFollowers],
                request:this.state.description
             }
             /* this.state.cardType */
             this.props.dispatch(Actions.addSession(obj))
         
             
                
          

        }
    }
  };
  
  
  handleHidePass = () => {
    this.setState({ passFlag: true, type: "text" });
  };
  handleShowPass = () => {
    this.setState({ passFlag: false, type: "password" });
  };
  handleCVChange = (e) => {
    this.changeHandler(e);
    this.setState({
      cv: e.target.files[0],
    });
    document.getElementById("file-name99").innerHTML = e.target.files[0].name;
  };
  handleDateChange = (e) => {
    /*   this.changeHandler(e) */
    this.setState({
      cv: e.target.files[0],
    });
    document.getElementById("file-name88").innerHTML = e.target.files[0].name;
  };

 
  componentWillMount(){
    this.props.dispatch(Actions.getUsers())
  }
  componentWillReceiveProps(nextProps){
    let resNumber1 =nextProps.addSessionReducer;    
    if (!resNumber1.isLoading) {
      if (resNumber1.errMsg == null && !(resNumber1.data===undefined)) {
          if(this.state.adminSignFlag){
            this.setState({loaderFlag:false,show:true})
            this.setState({
              fileNumber:'',
              fname:'',
              LocatioSession:'',
              CourtRoom:'',
              CaseNo:'',
              CaseType:'',
              ClientType:'',
              DefendantType:'',
              DefendantName:'',
              PreviousSessionDecision:'',
              NextSessionDecision:'',
              DecisionMaker:'',
              CaseFollowers:'',
              description:'',
              locationTime:'',
              nextTime:'',
              previousTime:''
            });
           
          }
      } else {
      this.setState({loaderFlag:false,errMessage:resNumber1.errMsg})
      }
    }
    /* getUsersReducer */
    let resNumber2 =nextProps.getUsersReducer;
 
    if (!resNumber2.isLoading) {
      if (resNumber2.errMsg == null && !(resNumber2.data===undefined)) {
        if(this.state.addCustomerFlag){
         this.setState({addCustomerFlag:false,errMsgUser:'',customers:resNumber2.data.users})
        }
 /*      console.log(resNumber2.data.users) */
      }else{
        this.setState({errMsgUser:resNumber2.errMsg})
        if(resNumber2.errMsg==="This token is expire! Please log in again"){
          this.props.dispatch(Actions.logoutAdmin())
                }
      }
    }
    let resNumber3 =nextProps.getUserCreateInvoceFilesReducer;
 
    if (!resNumber3.isLoading) {
      if (resNumber3.errMsg == null && !(resNumber3.data===undefined)) {
        if(resNumber3.data.file.length>0){
          resNumber3.data.file.map((item)=>{
            let obj={ level: item.fileNo, value: item.fileNo }
            this.state.options1.push(obj)
            this.setState({fileError:false,fileLoading:false})
          })
     
      
        }else{
          if(this.state.getFileFlag){
            this.setState({fileError:true,fileLoading:false,getFileFlag:false})
          }
        
        }
    /*     if(this.state.addCustomerFlag){ */
    /*       console.log(resNumber3,"gggggggggggggggggg") */
      }else{
        this.setState({fileLoading:false,fileError1:resNumber2.errMsg}) 
      }
    }
  }  
     
  handleSearch=(e)=>{
    let search=e.target.value
    this.setState({searchFlag:true,key:search})
 
     
   }  
   handleCustomer=(item)=>{
    this.setState({options1:[],fileError1:'',fileLoading:true,addedCustomer:item,customerAdded:true,email:item.email,fname:item.firstName+" "+item.lastName,UAEAddress:item.address,MobileNumber:item.phoneNumber,userId:item._id,getFileFlag:true})
    this.props.dispatch(Actions.getAllUsersInvoiceFiles(item._id))
   /*  console.log(item) "5ef1e66c55faca3525f432d7" */
  }
   
  handleArabic=(e)=>{
    if(e.target.checked){
this.setState({typingArabic:true})
    }else{
      this.setState({typingArabic:false})
    }
  }
  render() {
    const options1=[
      { level: 'Select Type', value: '' },
    /*   { level: 'CARD', value: 'CARD' }, */
      { level: 'CASH', value: 'CASH' }
  ]
  const options8888=[
    { level: 'Choose Client First', value: '' },
  /*   { level: 'CARD', value: 'CARD' }, */
    { level: '101', value: '101' },
    { level: '115', value: '150' }
]
const options8887=[
  { level: 'Select Lawyer', value: '' },
/*   { level: 'CARD', value: 'CARD' }, */
  { level: 'Bader Al Zaabi', value: 'Bader Al Zaabi' },
  { level: 'Bader Al Zaabi', value: 'Bader Al Zaabi' }
]
const options8889=[
  { level: 'Select Lawyer', value: '' },
/*   { level: 'CARD', value: 'CARD' }, */
  { level: 'Munir Ahmed', value: 'Munir Ahmed' },
  { level: 'Munir Ahmed', value: 'Munir Ahmed' }
]
    const optionsLawyer = [
      { level: "Bader Al Zaabi", value: "Bader Al Zaabi" },
      { level: "Bader Al Zaabi", value: "Bader Al Zaabi" },
      { level: "Bader Al Zaabi", value: "Bader Al Zaabi" },
    ];
    const options2 = [
      { level: "Select Our Pricing Plan* ", value: "" },
      { level: "Basic Plan", value: "Basic Plan" },
      { level: "Silver", value: "Silver" },
      { level: "Gold", value: "Gold" },
    ];

    const options = [
      { level: "Select a Case* ", value: "Case Type" },
      { level: "Criminal Cases", value: "Criminal Cases" },
      { level: "Corporate Law", value: "Corporate Law" },
      { level: "Arbitration", value: "Arbitration" },
      {
        level: "International/ Interpol Cases",
        value: "International/ Interpol Cases",
      },
      { level: "Financial Crime", value: "Financial Crime" },
      { level: "Family business", value: "Family business" },
      { level: "Commercial", value: "Commercial" },
      { level: "Banking", value: "Banking" },
      {
        level: "Construction & Infrastructure",
        value: "Construction & Infrastructure",
      },
      { level: "Competition", value: "Competition" },
      {
        level: "Data Privacy & Cybersecurity",
        value: "Data Privacy & Cybersecurity",
      },
      { level: "Intellectual Property", value: "Intellectual Property" },
      { level: "Merger & Acquisitions", value: "Merger & Acquisitions" },
      { level: "Private Notary", value: "Private Notary" },
      { level: "Insurance", value: "Insurance" },
      { level: "Capital Market", value: "Capital Market" },
      { level: "Private Client Services", value: "Private Client Services" },
      { level: "Family Law", value: "Family Law" },
      { level: "Business Law", value: "Business Law" },
      { level: "Real Estate Law", value: "Real Estate Law" },
      { level: "Injury Law", value: "Injury Law" },
      { level: "Educational Law", value: "Educational Law" },
      { level: "VAT Law", value: "VAT Law" },
      { level: "Tax Law Advisory", value: "Tax Law Advisory" },
      { level: "International Tax Law", value: "International Tax Law" },
      { level: "Technology/Cyber Law", value: "Technology/Cyber Law" },
      { level: "Chemical", value: "Chemical" },
      { level: "Aviation", value: "Aviation" },
      { level: "Consumer Product", value: "Consumer Product" },
      { level: "Financial institution", value: "Financial institution" },
      {
        level: "Manufacture and Industrial",
        value: "Manufacture and Industrial",
      },
      { level: "Maritime & Shipping", value: "Maritime & Shipping" },
      { level: "Media", value: "Media" },
      { level: "Mining and Metals", value: "Mining and Metals" },
      { level: "Oil and Gas", value: "Oil and Gas" },
      {
        level: "Pharmaceuticals and Healthcare",
        value: "Pharmaceuticals and Healthcare",
      },
      { level: "Power", value: "Power" },
      { level: "Private Equity", value: "Private Equity" },
      { level: "Sovereigns", value: "Sovereigns" },
      { level: "Telecommunication", value: "Telecommunication" },
      { level: "Space Laws", value: "Space Laws" },
      { level: "Establishing Business", value: "Establishing Business" },
      { level: "Anti-Money Laundering", value: "Anti-Money Laundering" },
      { level: "Drug Law", value: "Drug Law" },
      { level: "Multinational Disputes", value: "Multinational Disputes" },
      { level: "Labor Law", value: "Labor Law" },
      { level: "Others", value: "Others" }
    ];

    return (
      <div className="admin-cutomer-profile-area" >
      <div className="receipnest-file-customer-files-area" >
    <div  className="receipnest-file-customer-files-area655" style={{paddingBottom:'100px'}}>
      <div style={{padding:'20px',paddingLeft:'20px',paddingRight:'20px'}}>
   
<div className="visitor-form-border-box1">
        <div className="visitor-form-border-box-left1">
m
</div>
<div className="visitor-form-border-box-right1">
j
</div>
        </div>
        <form onSubmit={this.submitHandler} className="counsellingForm">
        
          <div className="caseFiletopAreaHeaderREceipnest">
            <h1 style={{fontWeight:'550'}}>Add Session <span className="arabic-style-font-visitor" style={{marginLeft:'20px'}}>أضف الجلسة</span></h1>
            <img src={require("../../images/logo/logo55.png")} />
          </div>
          <div className="caseFiletopAreaHeaderb">
            <p>
              {" "}
              <p >
                Date{" "}
                <span className="date-label-deisgin-box">
                  {new Date().getDate() + 1 > 9 ? "" : 0}
                  {new Date().getDate()}
                </span>{" "}
                <span className="date-label-deisgin-box">
                  {new Date().getMonth() + 1 > 9 ? "" : 0}
                  {new Date().getMonth() + 1}
                </span>{" "}
                <span className="date-label-deisgin-box">
                  {new Date().getFullYear()}
                </span>{" "}
              </p>
            </p>
            <div className="file-no-area" >
              {/* <p style={{fontSize:'15px'}}>File no. BZ/....</p> */}
            </div>
          </div>
          <div className="visitor-form-border-box">
        <div className="visitor-form-border-box-left">
m
</div>
<div className="visitor-form-border-box-right">
j
</div>
        </div>
          <div className="client-area-design" style={{display:'flex',justifyContent:'space-between'}}>
           
            <h3 style={{fontWeight:'550'}}>{/*  Client*<span className="arabic-style-font-visitor" style={{marginLeft:'20px',fontSize:'24px'}}>الموكل</span> */}</h3>
     
           <div className="visitor-type-direction" style={{ marginTop: "10px",marginBottom:'0px' }}>
           <p style={{marginBottom:'15px' }}>  Typing In Arabic </p>  <div>  <label className="switch99"><input onChange={this.handleArabic} type="checkbox" id="togBtn" /><div className="slider99 round99"><span className="on99">Yes</span><span className="off99">No</span></div></label>
</div>
           </div>
          </div>
          <div className="row">
            
         
           
            <div className="col-12 col-md-6">
            <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
    Add Client*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
إضافة عميل
               </span>
                </div>
            <div className="receptionist-note-add-customer-session">
              <p> <Icon   name="add user" />
              <Dropdown
    text={this.state.customerAdded?' choose another Client':' Add Client'}
    floating
    className='icon'
    defaultOpen={this.state.open}
  >
    <Dropdown.Menu>
      <Input icon='search' onChange={this.handleSearch} placeholder="Type Client Name" onClick={e => e.stopPropagation()} iconPosition='left' name='search' />
     {this.state.errMsgUser.length>0 ? <Dropdown.Item>
        <center><p style={{color:'red'}}>{this.state.errMsgUser}</p></center>
        </Dropdown.Item>:''}
      
      <Dropdown.Header style={{fontSize:'14px',textTransform:'capitalize',fontWeight:'520'}} icon='user outline' content='Existing Clients' />
  
      <Dropdown.Divider />
     {/*  <div  className="dropSown-area-addcutomer"> */}
     <Dropdown.Menu scrolling>
      {this.state.customers.map((item,index)=>{
    
        if(this.state.searchFlag){
       
          var reg45 = new RegExp(this.state.key.split('').join('.*'), 'i');
          if ((item.firstName+' '+item.lastName).match(reg45)){
            return <Dropdown.Item onClick={()=>this.handleCustomer(item)}>
            <span style={{fontSize:'14px',fontWeight:'520'}}>{item.firstName+' '+item.lastName}</span> <br />
        <span  style={{fontSize:'13px',fontWeight:'500'}}>{item.phoneNumber}</span>
          </Dropdown.Item>
          }else{
            if(index===0){
              return   <Dropdown.Item key={index}>
              <span  style={{fontSize:'14px',fontWeight:'520'}}>Not Found</span>
                </Dropdown.Item>
            }
      
          }
        }else{
          return <Dropdown.Item onClick={()=>this.handleCustomer(item)}>
          <span style={{fontSize:'14px',fontWeight:'520'}}>{item.firstName+' '+item.lastName}</span> <br />
      <span  style={{fontSize:'13px',fontWeight:'500'}}>{item.phoneNumber}</span>
        </Dropdown.Item>
        }
     
        
      })}
   
      </Dropdown.Menu>
    
   
    {/*   <Dropdown.Divider />
      <Dropdown.Item
      >
        <center> <p> <center><i className="fa fa-plus-circle"></i> Add Customers</center></p></center>
        </Dropdown.Item> */}
     
    </Dropdown.Menu>
  </Dropdown>
              </p>
        {this.state.customerAdded?<Icon style={{marginTop:'-5px'}} color="green" name="check circle" />:<Icon style={{marginTop:'-5px'}} color="white" name="check circle" />}
            </div>
          {this.state.submitFlag?this.state.customerAdded?'':<p style={{fontSize:'12px',fontWeight:'550',color:'red'}}> Must select the Client</p>:''}

            </div>
            <div className="col-12 col-lg-6 col-md-6">
                        <div className="formInput">
                 
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
    File No*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
رقم الملف 
               </span>
                </div>
                                    <select
                                        value={this.state.fileNumber} 
                                        className="form-control"
                                         onChange={this.changeHandler} 
                                       /*   required */
                                        name="fileNumber">
                                        {this.state.options1.map(option => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.level}
                                            </option>
                                        ))}
                                    </select>
                                    {this.state.error.fileNumber && <p>{this.state.error.fileNumber}</p>}
                                    {this.state.fileError ? <p style={{color:'red'}}>You don't have any Open a File <Link to='/createFile' onClick={this.handleOpenFile} style={{color:'white',backgroundColor:'#666666',paddingLeft:'5px',paddingRight:'5px',borderRadius:'10px'}} >Open File</Link> </p>:''}
                                    {this.state.fileLoading?<p style={{color:'red'}}>Loading Please wait...  </p>:''}
                                    <p style={{color:'red'}}>{this.state.fileError1}  </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
                  Location of  circuit session*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
مكان الجلسة الدائرة
                </span>
                </div>
                <input
                  placeholder=""
                  dir={this.state.typingArabic?"rtl":"ltr"}
                  value={this.state.LocatioSession} 
                  className="form-control"
                  onChange={this.changeHandler} 
                  name="LocatioSession"
              /*     required */
                  type="text"
                />
 {this.state.error.LocatioSession && <p>{this.state.error.LocatioSession}</p>}
               
              </div>
             </div> 
              <div className="col-12 col-md-4">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
                  Court Room
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
غرفة المحكمة

                </span>
                </div>
                <input
                  placeholder=""
                  dir={this.state.typingArabic?"rtl":"ltr"}
                  value={this.state.CourtRoom} 
                  className="form-control"
                  onChange={this.changeHandler} 
                  name="CourtRoom"
                  type="text"
                />
 {this.state.error.CourtRoom && <p>{this.state.error.CourtRoom}</p>}
               
              </div>
            </div>
           
            <div className="col-12 col-md-4">
              <div className="formInput22">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
           Date & time*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
التاريخ والوقت

                </span>
                </div>
                <DatePicker 
                     className="form-control"
                     onChange={(e)=>this.setState({locationTime:e._d.toISOString()})} 
                     name="dateTimeLocation"
                style={{height:'45px',width:'100%'}} showTime  />
           
                {this.state.submitFlag?<p style={{color:'red',fontSize:'12px',fontWeight:'550'}}>{this.state.locationTime.length>0?'':"Court Date&Time Can't Empty"}</p>:''}
               
              </div>
            </div>
            
            <div className="col-12 col-md-6">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
                  Case No*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
رقم الدعوى
                </span>
                </div>
                <input
                  placeholder=""
                  dir={this.state.typingArabic?"rtl":"ltr"}
                  value={this.state.CaseNo} 
                  className="form-control"
                  onChange={this.changeHandler} 
                  name="CaseNo"
                  type="text"
                />

{this.state.error.CaseNo && <p>{this.state.error.CaseNo}</p>}
              </div>
             </div> 

            <div className="col-12 col-lg-6 col-md-6">
                        <div className="formInput">
                 
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
    Case Type*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
نوع القضية

               </span>
                </div>
                                    <select
                                  
                                         onChange={this.changeHandler} 
                                         value={this.state.CaseType} 
                                         className="form-control"
                                         onChange={this.changeHandler} 
                                         name="CaseType"
                                         >
                                        {options.map(option => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.level}
                                            </option>
                                        ))}
                                    </select>
                                    {this.state.error.CaseType && <p>{this.state.error.CaseType}</p>}
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
                  Client Name*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
اسم العميل
                </span>
                </div>
                <input
                  placeholder=""
                  dir={this.state.typingArabic?"rtl":"ltr"}
                  name="fname"
                  value={this.state.fname} 
                  className="form-control"
                  onChange={this.changeHandler} 
                  type="text"
                />
 {this.state.error.fname && <p>{this.state.error.fname}</p>}
               
              </div>
             </div> 
             <div className="col-12 col-md-6">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
                  Client Type*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
نوع العميل
                </span>
                </div>
                <input
                  placeholder=""
                  dir={this.state.typingArabic?"rtl":"ltr"}
                  name="ClientType"
                  value={this.state.ClientType} 
                  className="form-control"
                  onChange={this.changeHandler} 
                  type="text"
                />

{this.state.error.ClientType && <p>{this.state.error.ClientType}</p>}     
              </div>
             </div> 
             <div className="col-12 col-md-6">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
                 Defendant Name*

                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
اسم المدعى عليه
                </span>
                </div>
                <input
                  placeholder=""
                  dir={this.state.typingArabic?"rtl":"ltr"}
                  name="DefendantName"
                  value={this.state.DefendantName} 
                  className="form-control"
                  onChange={this.changeHandler} 
                  type="text"
                />
 {this.state.error.DefendantName && <p>{this.state.error.DefendantName}</p>}
               
              </div>
             </div> 
             <div className="col-12 col-md-6">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
                  Defendant Type*

                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
نوع المدعى عليه
                </span>
                </div>
                <input
                  placeholder=""
                  dir={this.state.typingArabic?"rtl":"ltr"}
                  name="DefendantType"
                  value={this.state.DefendantType} 
                  className="form-control"
                  onChange={this.changeHandler} 
                  type="text"
                />
 {this.state.error.DefendantType && <p>{this.state.error.DefendantType}</p>}
               
              </div>
             </div> 
             <div className="col-12 col-md-6">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
                Previous Session Decision*

                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
قرار الجلسة السابقة

                </span>
                </div>
                <input
                  placeholder=""
                  dir={this.state.typingArabic?"rtl":"ltr"}
                  name="PreviousSessionDecision"
                  value={this.state.PreviousSessionDecision} 
                  className="form-control"
                  onChange={this.changeHandler} 
                  type="text"
                />

{this.state.error.PreviousSessionDecision && <p>{this.state.error.PreviousSessionDecision}</p>}
              </div>
             </div> 
             <div className="col-12 col-md-6">
              <div className="formInput22">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
           Date & time*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
التاريخ والوقت

                </span>
                </div>
                <DatePicker
                 onChange={(e)=>this.setState({previousTime:e._d.toISOString()})} 
                style={{height:'45px',width:'100%'}} showTime  />
 
  {this.state.submitFlag?<p style={{color:'red',fontSize:'12px',fontWeight:'550'}}>{this.state.previousTime.length>0?'':"Previous Session Date&Time Can't Empty"}</p>:''}
               
               
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
               Next Session Decision*

                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
تاريخ الجلسة القادمة القرار

                </span>
                </div>
                <input
                  placeholder=""
                  dir={this.state.typingArabic?"rtl":"ltr"}
                  name="NextSessionDecision"
                  value={this.state.NextSessionDecision} 
                  className="form-control"
                  onChange={this.changeHandler} 
                  type="text"
                />
 {this.state.error.NextSessionDecision && <p>{this.state.error.NextSessionDecision}</p>}
               
              </div>
             </div> 
             <div className="col-12 col-md-6">
              <div className="formInput22">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
           Date & time*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
التاريخ والوقت

                </span>
                </div>
                <DatePicker
                 onChange={(e)=>this.setState({nextTime:e._d.toISOString()})} 
                style={{height:'45px',width:'100%'}} showTime  />
              {this.state.submitFlag?<p style={{color:'red',fontSize:'12px',fontWeight:'550'}}>{this.state.nextTime.length>0?'':"Next Session Date&Time Can't Empty"}</p>:''}
               
               
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
   
Requests*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
الطلبات
               </span>
                </div>
                <input
                  dir={this.state.typingArabic?"rtl":"ltr"}
                  className="form-control"
                  value={this.state.description}
                  onChange={this.changeHandler}
                  placeholder="Detail of Requests*"
                  name="description"
                />
                {this.state.error.description && (
                  <p>{this.state.error.description}</p>
                )}
              </div>
            </div>
           
            
         


            <div className="col-12 col-lg-6 col-md-6">
                        <div className="formInput">
                 
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
    Case Followers*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
الشخص المتابع للقضية
               </span>
                </div>
                                    <input
                                         onChange={this.changeHandler} 
                                         name="CaseFollowers"
                                         value={this.state.CaseFollowers} 
                                         className="form-control"
                                         onChange={this.changeHandler} 
                                        />
                                    {this.state.error.CaseFollowers && <p>{this.state.error.CaseFollowers}</p>}
                        </div>
                    </div>
      


                    <div className="col-12">
                        <div className="formInput">
                 
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
    Decision Maker*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
صاحب القرار
               </span>
                </div>
                                    <textarea
                                         onChange={this.changeHandler} 
                                         name="DecisionMaker"
                                         value={this.state.DecisionMaker} 
                                         className="form-control"
                                         onChange={this.changeHandler} 
                                        row="2"
                                        ></textarea>
                                    {this.state.error.DecisionMaker && <p>{this.state.error.DecisionMaker}</p>}
                        </div>
                    </div>
            
           
            <div className="col-12" style={{ marginTop: "0px" }}>
                <p style={{color:'red',fontWeight:'12px',fontWeight:'550'}}>{this.state.errMessage}</p>
              <div className="signon-bottom-area1">
             {/*  <ReCAPTCHA
                       ref={recaptchaRef}
                       sitekey="6LenmOwUAAAAAJV_zauHgqJ7FOe_bQZfztCRVGaN"
                       onChange={this.onChangeCaptha}
                       /> */}
                 <div></div>      
            <div className="customer-file-loader-with-button" >  <span style={{width:'50px',height:'50px'}}>{this.state.loaderFlag?<div style={{marginRight:'10px'}} className="loader"></div> :""}</span>  <button style={{display:'inline'}} disabled={this.state.buttonFlag}  type="submit">Submit </button>
             </div>
             </div>
            </div>
          </div>
          <Modal show={this.state.show} onHide={this.handleClose} size="sm" centered  >
        <Modal.Header closeButton>
            <Modal.Title className="heading-modal"
            >
         <img style={{width:'30px'}} src={require('../../images/about/man-01.png')} />  Case Session
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
              <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
               <center>
                   <p><i style={{fontSize:'120px'}} class="fa fa fa-check-circle-o"></i></p>
                   <h2>thank you!</h2>
                   <p>The Session against this Case is Succesfully Added thanks</p>
                   <button className="sent-button-des"onClick={this.handleClose} ><Link to="/sessions"  style={{color:'white'}} >OK</Link></button>
               </center>
              </div>
           
          </Modal.Body>
      
        </Modal>
        </form>
  </div>
  </div>
       </div>
       </div>
    );
  }
}
const mapStateToProps=(state)=> {
  return {
    addSessionReducer: state.AddSessionForm1.addSessionReducer,
    getUsersReducer:state.AddSessionForm1.getUsersReducer,
    getUserCreateInvoceFilesReducer:state.AddSessionForm1.getUserCreateInvoceFilesReducer


  };
}
export default compose(
  withReducer("AddSessionForm1", reducer),
  connect(mapStateToProps),withRouter
)(AddSessionForm); 