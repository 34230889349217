import React,{useEffect,useState} from 'react'
import { NavLink, Link } from 'react-router-dom'
import '../sideBar/style.scss';
import '../sideBar/style.css';
import './style.css'
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import SessionRoll from './sessions'
import AdminSideBar from '../sideBar/sideBar1'
import withReducer from "../../store/withReducer";
import Header from '../header'
import store from "../../store";
import { useDispatch, useSelector } from "react-redux";

function SessionRollArea ()  {
    
    return (
        <div>  
                <Header />  
        <div className="adminPanel-area">
         
            <div className="container-custom">
                <div className="adminPanelBackarea">
            
                <div className="row">
              
                    <div className="col-xs-12 col-lg-0 col-sm-12 admin-cols">
                <AdminSideBar />
                    </div>
                    <div className="col-lg-12 col-xs- col-sm-12 admin-cols1">
                    <SessionRoll />
                    </div>
                </div>
                </div>
            </div>
        </div>
        </div>
    )
}
export default  withReducer("SessionRollArea1", reducer)
  (SessionRollArea); 