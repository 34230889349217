import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const CASE_SESSION_CUSTOMERS = "CASE_SESSION_CUSTOMERS";
export const CASE_SESSION_CUSTOMERS_LOADING =
"CASE_SESSION_CUSTOMERS_LOADING";
export const CASE_SESSION_CUSTOMERS_LOADING_FAILED =
"CASE_SESSION_CUSTOMERS_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const getSessionsLoading = ms => ({
    type: CASE_SESSION_CUSTOMERS_LOADING,
    payload: ms
    });
    
    export const getSessionsLoadingFailed = ms => ({
    type: CASE_SESSION_CUSTOMERS_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function getSessions(obj) {
   
    const request = axios.get(
            `https://prod-legalemirates.wowets.com/fileService/receptionistRoutes/api/v1/caseSessions/getCaseSessionsWithPagination?startDate=${obj.startDate}&endDate=${obj.endDate}&pageNo=${obj.pageNo}&limit=${obj.limit}`, {
                headers: { 'Authorization': 'bearer ' +localStorage.receptionistToken}
              });
    
    return dispatch => {

    dispatch(getSessionsLoading());
    request
    .then(response => {   
  
    if (response.status == 200 || response.status == 201) {
   /*   console.log(response,"response getSessions") */
    dispatch({
    type: CASE_SESSION_CUSTOMERS,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        getSessionsLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(getSessionsLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(getSessionsLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(getSessionsLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


