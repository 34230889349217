import React, { Component } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { NavLink, Link, withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./switch.css";
/* import "./style.scss"; */
import SignatureCanvas from "react-signature-canvas";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import {
  Dropdown,
  Input,
  Label,
  Icon,
  Checkbox,
  TextArea,
  Header,
  Feed,
} from "semantic-ui-react";

import "./formStyle.scss";
import {
  Button,
  Modal,
  FormGroup,
  FormControl,
  Accordion,
  Col,
  Row,
  Card,
  InputGroup,
  Container,
} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();
class CustomerCaseForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      fname: "",
      Nationality: "",
      Occupation: "",
      Employer: "",
      UAEAddress: "",
      emiratesId: "",
      PermanentAddress: "",
      MobileNumber: "",
      HomeTel: "",
      OfficeTel: "",
      Fax: "",
      email: "",
      description: "",
      case: "",
      LawyerName: "Bader Al Zaabi",
      caseNumber: "",
      OtherParty: "",
      Year: "",
      OPLawyer: "",
      Court: "",
      Stagelitgation: "",
      Accusation: "",
      description1: "",
      description2: "",
      AmountClaim: "",
      ProfessionalFees: "",
      InAdvance: "",
      FirstInstance: "",
      Appeal: "",
      CourtFee: "",
      Cassation: "",
      Execution: "",
      ProfessionalFee1: "",
      Max: "",
      OfficialFee55: "",
      Administrativefees: "",
      termsConditions: false,
      termsSubmitFlag: false,
      error: {},
      type: "password",
      passFlag: false,
      date88: "text",
      date99: "text",
      termsFlag: true,
      trimmedDataURL: null,
      paymentInvoice: false,
      captcha: null,
      buttonFlag: false,
      registrationFlag: true,
      show: false,
      caseType: "",
      stage: "",
      loaderFlag: false,
      fileOptions: ["FILE_REG"],
      ConsultationFlag: false,
      attorneyFlag: false,
      behalfFlag: false,
      CorporateFlag: false,
      BankFlag: false,
      IndividualFlag: false,
      errMessage: "",
      errMsgUser: "",
      customers: [],
      addCustomerFlag: true,
      searchFlag: false,
      key: "",
      addedCustomer: {},
      customerAdded: false,
      userId: "",
      fileNo: 1,
      typingArabic: false,
      preview: "",
      preview1: "",
      preview2: "",
      preview3: "",
      preview4: "",
      emiratePhoto: "",
      submitFlag: false,
      options1: [],
      fileNumber: "",
      clientType: "",
      fileLoading: false,
      fileError: false,
      fileError1: "",
      getFileFlag: false,
      adminSignFlag: false,
    };
  }
  handleClose = () => {
    this.setState({ show: false });
    this.props.history.push("/cases");
    window.scrollTo({ top: 0, behavior: "smooth" });
    localStorage.setItem("defaultSelectedKeys", "AllCasesReceptionist");
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  schema = {
    fname: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Full Name can not be Empty";
              break;
          }
        });
        return errors;
      }),
    caseType: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = " Case type can not be Empty";
              break;
          }
        });
        return errors;
      }),
    stage: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "stage can not be Empty";
              break;
          }
        });
        return errors;
      }),
    /* stage */
    fileNumber: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = " File No can not be Empty";
              break;
          }
        });
        return errors;
      }),
    clientType: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = " Client Type can not be Empty";
              break;
          }
        });
        return errors;
      }),
    UAEAddress: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "UAE Address can not be Empty";
              break;
          }
        });
        return errors;
      }),
    emiratesId: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Emirates ID can not be Empty";
              break;
          }
        });
        return errors;
      }),

    MobileNumber: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Mobile Number can not be Empty";
              break;
          }
        });
        return errors;
      }),

    description: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Case description can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    LawyerName: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Lawyer Name can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    PermanentAddress: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Permanent Address  can not be Empty ";
              break;
          }
        });
        return errors;
      }),

    email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.email":
              err.message = "email mast be A Valid Email";
              break;
            default:
              err.message = "email can not be Empty";
              break;
          }
        });
        return errors;
      }),
    Nationality: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Nationality must be select ";
              break;
          }
        });
        return errors;
      }),

   
  };
  changeHandler = (event) => {
    const error = { ...this.state.error };
    const errorMassage = this.validationProperty(event);
    if (errorMassage) {
      error[event.target.name] = errorMassage;
    } else {
      delete error[event.target.name];
    }
    this.setState({
      [event.target.name]: event.target.value,
      error,
    });
  };
  handleChange = (value) => {
    this.setState({
      country: value,
    });
  };

  validationProperty = (event) => {
    const Obj = { [event.target.name]: event.target.value };
    const schema = { [event.target.name]: this.schema[event.target.name] };
    const { error } = Joi.validate(Obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const form = {
      fname: this.state.fname,
      Nationality: this.state.Nationality,
      UAEAddress: this.state.UAEAddress,
      MobileNumber: this.state.MobileNumber,
      LawyerName: this.state.LawyerName,
      caseType: this.state.caseType,
      email: this.state.email,
      description: this.state.description,
      PermanentAddress: this.state.PermanentAddress,
      emiratesId: this.state.emiratesId,
      fileNumber: this.state.fileNumber,
      stage: this.state.stage,
      clientType: this.state.clientType,
    };
    const { error } = Joi.validate(form, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  submitHandler = (event) => {
    event.preventDefault();
    this.setState({ submitFlag: true });
    const error = this.validate();
    if (error) {
      this.setState({
        error: error || {},
      });
    } else {
      if (this.state.termsFlag) {
        this.setState({ termsSubmitFlag: true });
        /* 
                termsSubmitFlag
                termsFlag
                */
      } else {
        this.setState({ adminSignFlag: true, loaderFlag: true });

        /*openACase*/
        /* openCAseReducer 
           trimmedDataURL
           */
        let Data = new FormData();
        Data.append("userId", this.state.userId);
        Data.append("emiratesId", this.state.photo);
        Data.append("passport", this.state.photo1);
        Data.append("visa", this.state.photo2);
        Data.append("fileNo", this.state.fileNumber);
        Data.append("poaCopy", this.state.photo3);
        Data.append("basicCaseEvidance", this.state.photo4);
        Data.append("emiratesIdNumber", this.state.emiratesId);
        Data.append("nationality", this.state.Nationality);
        Data.append("employerType", this.Employer.value);
        Data.append("permanentAddress", this.state.PermanentAddress);
        Data.append("homeTel", this.HomeTel.value);
        Data.append("officeTel", this.OfficeTel.value);
        Data.append("fax", this.Fax.value);
        Data.append("clientType", this.state.clientType);
        Data.append("lawyerName", this.lawyerName.value);
        Data.append("stage", this.state.stage);
        Data.append("caseType", this.state.caseType);
        Data.append("poaType", this.poaType.value);
        Data.append("opponentName", this.oppenentName.value);
        Data.append("opponentNationality", this.opponentNationality.value);
        Data.append("opponentPhoneNumber", this.opponentPhoneNumber.value);
        Data.append("opponentEmail", this.opponentEmail.value);
        Data.append("opponentAddress", this.opponentAddress.value);
        Data.append("accusation", this.accusation.value);
        Data.append("caseDescription", this.state.description);

        this.props.dispatch(Actions.openACase(Data));
      }
    }
  };
  handleDateChange = (e) => {
    /*   this.changeHandler(e) */
    this.setState({
      cv: e.target.files[0],
    });
    document.getElementById("file-name88").innerHTML = e.target.files[0].name;
  };
  handleConditions = () => {
    if (this.state.termsFlag) {
      this.setState({ termsFlag: false });
    } else {
      this.setState({ termsFlag: true });
    }
  };
 

  /* ConsultationFlag */
  handleConsultation = () => {
    if (this.state.ConsultationFlag) {
      let newArray = this.state.fileOptions.filter(
        (item) => item !== "CONSULTATION"
      );
      this.setState({ ConsultationFlag: false, fileOptions: newArray });
    } else {
      this.state.fileOptions.push("CONSULTATION");
      this.setState({ ConsultationFlag: true });
    }
  };
  /*  checked={this.state.attorneyFlag}
                            onChange={this.handleAttorney} */
  handleAttorney = () => {
    if (this.state.attorneyFlag) {
      let newArray = this.state.fileOptions.filter(
        (item) => item !== "CREATE_ATTORNEY"
      );
      this.setState({ attorneyFlag: false, fileOptions: newArray });
    } else {
      this.state.fileOptions.push("CREATE_ATTORNEY");
      this.setState({ attorneyFlag: true });
    }
  };

  handleBehalf = () => {
    if (this.state.behalfFlag) {
      let newArray = this.state.fileOptions.filter(
        (item) => item !== "BEHALF_OF_ANOTHER"
      );
      this.setState({ behalfFlag: false, fileOptions: newArray });
    } else {
      this.state.fileOptions.push("BEHALF_OF_ANOTHER");
      this.setState({ behalfFlag: true });
    }
  };

  handleCorporate = () => {
    if (this.state.CorporateFlag) {
      let newArray = this.state.fileOptions.filter(
        (item) => item !== "CORPORATE"
      );
      this.setState({ CorporateFlag: false, fileOptions: newArray });
    } else {
      this.state.fileOptions.push("CORPORATE");
      this.setState({ CorporateFlag: true });
    }
  };
  handleBank = () => {
    if (this.state.BankFlag) {
      let newArray = this.state.fileOptions.filter((item) => item !== "BANK");
      this.setState({ BankFlag: false, fileOptions: newArray });
    } else {
      this.state.fileOptions.push("BANK");
      this.setState({ BankFlag: true });
    }
  };

  handleIndividual = () => {
    if (this.state.IndividualFlag) {
      let newArray = this.state.fileOptions.filter(
        (item) => item !== "INDIVIDUAL"
      );
      this.setState({ IndividualFlag: false, fileOptions: newArray });
    } else {
      this.state.fileOptions.push("INDIVIDUAL");
      this.setState({ IndividualFlag: true });
    }
  };
  componentWillMount() {
    this.props.dispatch(Actions.getUsers());
  }
  componentWillReceiveProps(nextProps) {
    let resNumber1 = nextProps.openCAseReducer;
    if (!resNumber1.isLoading) {
      if (resNumber1.errMsg == null && !(resNumber1.data === undefined)) {
        if (this.state.adminSignFlag) {
          this.setState({ loaderFlag: false, show: true });
          setTimeout(() => {
            this.setState({
              stage: "",
              fileNumber: "",
              termsSubmitFlag: false,
              fname: "",
              Employer: "",
              UAEAddress: "",
              emiratesId: "",
              PermanentAddress: "",
              MobileNumber: "",
              clientType: "",
              description: "",
              email: "",
              case: "",
              LawyerName: "",

              caseType: "",

              termsFlag: true,
              Nationality: "",
            });
          }, 1000);
        }
      } else {
        this.setState({ loaderFlag: false, errMessage: resNumber1.errMsg });
      }
    }
    /* getUsersReducer */
    let resNumber2 = nextProps.getUsersReducer;

    if (!resNumber2.isLoading) {
      if (resNumber2.errMsg == null && !(resNumber2.data === undefined)) {
        if (this.state.addCustomerFlag) {
          this.setState({
            addCustomerFlag: false,
            errMsgUser: "",
            customers: resNumber2.data.users,
          });
        }
        /*      console.log(resNumber2.data.users) */
      } else {
        this.setState({ errMsgUser: resNumber2.errMsg });
        if (resNumber2.errMsg === "This token is expire! Please log in again") {
          this.props.dispatch(Actions.logoutAdmin());
        }
      }
    }
    let resNumber3 = nextProps.getUserCreateInvoceFilesReducer;

    if (!resNumber3.isLoading) {
      if (resNumber3.errMsg == null && !(resNumber3.data === undefined)) {
        if (resNumber3.data.file.length > 0) {
          resNumber3.data.file.map((item) => {
            let obj = { level: item.fileNo, value: item.fileNo };
            this.state.options1.push(obj);
            this.setState({ fileError: false, fileLoading: false });
          });
        } else {
          if (this.state.getFileFlag) {
            this.setState({
              fileError: true,
              fileLoading: false,
              getFileFlag: false,
            });
          }
        }
        /*     if(this.state.addCustomerFlag){ */
        /*       console.log(resNumber3,"gggggggggggggggggg") */
      } else {
        this.setState({ fileLoading: false, fileError1: resNumber2.errMsg });
      }
    }
  }
  handleBack = () => {
    this.props.history.push(`/${localStorage.fileLink}`);
    window.scrollTo({ top: 60, behavior: "smooth" });
  };
  handleSearch = (e) => {
    let search = e.target.value;
    this.setState({ searchFlag: true, key: search });
  };
  handleCustomer = (item) => {
    this.setState({
      options1: [],
      fileError1: "",
      fileLoading: true,
      addedCustomer: item,
      customerAdded: true,
      email: item.email,
      fname: item.firstName + " " + item.lastName,
      UAEAddress: item.address,
      MobileNumber: item.phoneNumber,
      userId: item._id,
      getFileFlag: true,
    });
    this.props.dispatch(Actions.getAllUsersInvoiceFiles(item._id));
    /*  console.log(item) "5ef1e66c55faca3525f432d7" */
  };

  handleArabic = (e) => {
    if (e.target.checked) {
      this.setState({ typingArabic: true });
    } else {
      this.setState({ typingArabic: false });
    }
  };
  handleFileChange = (e) => {
    this.setState({
      photo: e.target.files[0],
      emiratePhoto: "ffff",
    });

    var reader = new FileReader();
    var url = reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = (e) => {
      this.setState({
        preview: [reader.result],
      });
    };
  };
  handleFileChange1 = (e) => {
    this.setState({
      photo1: e.target.files[0],
      BankReceiptError: "",
    });

    var reader = new FileReader();
    var url = reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = (e) => {
      this.setState({
        preview1: [reader.result],
      });
    };
  };
  handleFileChange2 = (e) => {
    this.setState({
      photo2: e.target.files[0],
      BankReceiptError: "",
    });

    var reader = new FileReader();
    var url = reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = (e) => {
      this.setState({
        preview2: [reader.result],
      });
    };
  };
  handleFileChange3 = (e) => {
    this.setState({
      photo3: e.target.files[0],
      BankReceiptError: "",
    });

    var reader = new FileReader();
    var url = reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = (e) => {
      this.setState({
        preview3: [reader.result],
      });
    };
  };
  handleFileChange4 = (e) => {
    this.setState({
      photo4: e.target.files[0],
      BankReceiptError: "",
    });

    var reader = new FileReader();
    var url = reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = (e) => {
      this.setState({
        preview4: [reader.result],
      });
    };
  };
  render() {
    const options1 = [
      { level: "Select Type", value: "" },
      /*   { level: 'CARD', value: 'CARD' }, */
      { level: "CASH", value: "CASH" },
    ];
    const optionsLawyer = [
      { level: "Bader Al Zaabi", value: "Bader Al Zaabi" },
      { level: "Bader Al Zaabi", value: "Bader Al Zaabi" },
      { level: "Bader Al Zaabi", value: "Bader Al Zaabi" },
    ];
    const options1111 = [
      { level: "Select Gender", value: "" },
      /*   { level: 'CARD', value: 'CARD' }, */
      { level: "Male", value: "male" },
      { level: "Female", value: "female" },
    ];
    const options2 = [
      { level: "Select Our Pricing Plan* ", value: "" },
      { level: "Basic Plan", value: "Basic Plan" },
      { level: "Silver", value: "Silver" },
      { level: "Gold", value: "Gold" },
    ];

    const options = [
      { level: "Select a Case* ", value: "Case Type" },
      { level: "Criminal Cases", value: "Criminal Cases" },
      { level: "Corporate Law", value: "Corporate Law" },
      { level: "Arbitration", value: "Arbitration" },
      {
        level: "International/ Interpol Cases",
        value: "International/ Interpol Cases",
      },
      { level: "Financial Crime", value: "Financial Crime" },
      { level: "Family business", value: "Family business" },
      { level: "Commercial", value: "Commercial" },
      { level: "Banking", value: "Banking" },
      {
        level: "Construction & Infrastructure",
        value: "Construction & Infrastructure",
      },
      { level: "Competition", value: "Competition" },
      {
        level: "Data Privacy & Cybersecurity",
        value: "Data Privacy & Cybersecurity",
      },
      { level: "Intellectual Property", value: "Intellectual Property" },
      { level: "Merger & Acquisitions", value: "Merger & Acquisitions" },
      { level: "Private Notary", value: "Private Notary" },
      { level: "Insurance", value: "Insurance" },
      { level: "Capital Market", value: "Capital Market" },
      { level: "Private Client Services", value: "Private Client Services" },
      { level: "Family Law", value: "Family Law" },
      { level: "Business Law", value: "Business Law" },
      { level: "Real Estate Law", value: "Real Estate Law" },
      { level: "Injury Law", value: "Injury Law" },
      { level: "Educational Law", value: "Educational Law" },
      { level: "VAT Law", value: "VAT Law" },
      { level: "Tax Law Advisory", value: "Tax Law Advisory" },
      { level: "International Tax Law", value: "International Tax Law" },
      { level: "Technology/Cyber Law", value: "Technology/Cyber Law" },
      { level: "Chemical", value: "Chemical" },
      { level: "Aviation", value: "Aviation" },
      { level: "Consumer Product", value: "Consumer Product" },
      { level: "Financial institution", value: "Financial institution" },
      {
        level: "Manufacture and Industrial",
        value: "Manufacture and Industrial",
      },
      { level: "Maritime & Shipping", value: "Maritime & Shipping" },
      { level: "Media", value: "Media" },
      { level: "Mining and Metals", value: "Mining and Metals" },
      { level: "Oil and Gas", value: "Oil and Gas" },
      {
        level: "Pharmaceuticals and Healthcare",
        value: "Pharmaceuticals and Healthcare",
      },
      { level: "Power", value: "Power" },
      { level: "Private Equity", value: "Private Equity" },
      { level: "Sovereigns", value: "Sovereigns" },
      { level: "Telecommunication", value: "Telecommunication" },
      { level: "Space Laws", value: "Space Laws" },
      { level: "Establishing Business", value: "Establishing Business" },
      { level: "Anti-Money Laundering", value: "Anti-Money Laundering" },
      { level: "Drug Law", value: "Drug Law" },
      { level: "Multinational Disputes", value: "Multinational Disputes" },
      { level: "Labor Law", value: "Labor Law" },
      { level: "Others", value: "Others" },
    ];

    return (
      <div className="admin-cutomer-profile-area">
        <div className="receipnest-file-customer-files-area">
          <div
            className="receipnest-file-customer-files-area655"
            style={{ paddingBottom: "100px" }}
          >
            <div
              style={{
                padding: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <div className="visitor-form-border-box1">
                <div className="visitor-form-border-box-left1">m</div>
                <div className="visitor-form-border-box-right1">j</div>
              </div>
              <form onSubmit={this.submitHandler} className="counsellingForm">
                <div className="caseFiletopAreaHeaderREceipnest">
                  <h1 style={{ fontWeight: "550" }}>
                    Open a Case{" "}
                    <span
                      className="arabic-style-font-visitor"
                      style={{ marginLeft: "20px" }}
                    >
                      فتح قضية
                    </span>
                  </h1>
                  <img src={require("../../images/logo/logo55.png")} />
                </div>
                <div className="caseFiletopAreaHeaderb">
                  <p>
                    {" "}
                    <p>
                      Date{" "}
                      <span className="date-label-deisgin-box">
                        {new Date().getDate() + 1 > 9 ? "" : 0}
                        {new Date().getDate()}
                      </span>{" "}
                      <span className="date-label-deisgin-box">
                        {new Date().getMonth() + 1 > 9 ? "" : 0}
                        {new Date().getMonth() + 1}
                      </span>{" "}
                      <span className="date-label-deisgin-box">
                        {new Date().getFullYear()}
                      </span>{" "}
                    </p>
                  </p>
                  <div className="file-no-area">
                    <p style={{ fontSize: "14px" }}>Case No BZ/0000</p>
                  </div>
                </div>
                <div className="visitor-form-border-box2">
                  <div className="visitor-form-border-box-left2">m</div>
                  <div className="visitor-form-border-box-right2">j</div>
                </div>
                <div
                  className="client-area-design"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3 style={{ fontWeight: "550" }}>
                    {" "}
                    Client*
                    <span
                      className="arabic-style-font-visitor"
                      style={{ marginLeft: "20px", fontSize: "24px" }}
                    >
                      الموكل
                    </span>
                  </h3>

                  <div
                    className="visitor-type-direction"
                    style={{ marginTop: "10px", marginBottom: "0px" }}
                  >
                    <p style={{ marginBottom: "10px" }}> Typing In Arabic </p>{" "}
                    <div>
                      {" "}
                      <label className="switch99">
                        <input
                          onChange={this.handleArabic}
                          type="checkbox"
                          id="togBtn"
                        />
                        <div className="slider99 round99">
                          <span className="on99">Yes</span>
                          <span className="off99">No</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="receptionist-note-add-customer-case">
                      <p>
                        {" "}
                        <Icon name="add user" />
                        <Dropdown
                          text={
                            this.state.customerAdded
                              ? " choose another"
                              : " Add Client"
                          }
                          floating
                          className="icon"
                          defaultOpen={this.state.open}
                        >
                          <Dropdown.Menu>
                            <Input
                              icon="search"
                              onChange={this.handleSearch}
                              placeholder="Type Client Name"
                              onClick={(e) => e.stopPropagation()}
                              iconPosition="left"
                              name="search"
                            />
                            {this.state.errMsgUser.length > 0 ? (
                              <Dropdown.Item>
                                <center>
                                  <p style={{ color: "red" }}>
                                    {this.state.errMsgUser}
                                  </p>
                                </center>
                              </Dropdown.Item>
                            ) : (
                              ""
                            )}

                            <Dropdown.Header
                              style={{
                                fontSize: "14px",
                                textTransform: "capitalize",
                                fontWeight: "520",marginTop:'50px',paddingBottom:'10px'
                              }}
                              icon="user outline"
                              content="Existing Clients"
                            />

                            <Dropdown.Divider />
                            {/*  <div  className="dropSown-area-addcutomer"> */}
                            <Dropdown.Menu scrolling>
                              {this.state.customers.map((item, index) => {
                                if (this.state.searchFlag) {
                                  var reg45 = new RegExp(
                                    this.state.key.split("").join(".*"),
                                    "i"
                                  );
                                  if (
                                    (
                                      item.firstName +
                                      " " +
                                      item.lastName
                                    ).match(reg45)
                                  ) {
                                    return (
                                      <Dropdown.Item
                                        onClick={() =>
                                          this.handleCustomer(item)
                                        }
                                      >
                                        <span
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "520",
                                          }}
                                        >
                                          {item.firstName + " " + item.lastName}
                                        </span>{" "}
                                        <br />
                                        <span
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {item.phoneNumber}
                                        </span>
                                      </Dropdown.Item>
                                    );
                                  } else {
                                    if (index === 0) {
                                      return (
                                        <Dropdown.Item key={index}>
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "520",
                                            }}
                                          >
                                            Not Found
                                          </span>
                                        </Dropdown.Item>
                                      );
                                    }
                                  }
                                } else {
                                  return (
                                    <Dropdown.Item
                                      onClick={() => this.handleCustomer(item)}
                                    >
                                      <span
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "520",
                                        }}
                                      >
                                        {item.firstName + " " + item.lastName}
                                      </span>{" "}
                                      <br />
                                      <span
                                        style={{
                                          fontSize: "13px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {item.phoneNumber}
                                      </span>
                                    </Dropdown.Item>
                                  );
                                }
                              })}
                            </Dropdown.Menu>

                            {/*   <Dropdown.Divider />
      <Dropdown.Item
      >
        <center> <p> <center><i className="fa fa-plus-circle"></i> Add Customers</center></p></center>
        </Dropdown.Item> */}
                          </Dropdown.Menu>
                        </Dropdown>
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-8">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="formInput">
                          <div className="visitor-form-top-field-text">
                            <p
                              style={{
                                marginBottom: "0px",
                                color: "#282e3f",
                                fontWeight: "600",
                                fontSize: "15px",
                              }}
                            >
                              Full Name*
                            </p>
                            <span
                              style={{
                                marginBottom: "0px",
                                color: "#282e3f",
                              }}
                            >
                              الاسم الكامل
                            </span>
                          </div>
                          <input
                            placeholder=""
                            value={this.state.fname}
                            dir={this.state.typingArabic ? "rtl" : "ltr"}
                            name="fname"
                            className="form-control"
                            type="text"
                          />

                          {this.state.error.fname && (
                            <p style={{ fontWeight: "550", marginTop: "8px" }}>
                              {this.state.error.fname}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="formInput">
                          <div className="visitor-form-top-field-text">
                            <p
                              style={{
                                marginBottom: "0px",
                                color: "#282e3f",
                                fontWeight: "600",
                                fontSize: "15px",
                              }}
                            >
                              Mobile Number*
                            </p>
                            <span
                              style={{
                                marginBottom: "0px",
                                color: "#282e3f",
                              }}
                            >
                              رقم الهاتف المحمول
                            </span>
                          </div>
                          <input
                            placeholder=""
                            value={this.state.MobileNumber}
                            name="MobileNumber"
                            /* onChange={this.changeHandler} */
                            className="form-control"
                            type="text"
                          />

                          {this.state.error.MobileNumber && (
                            <p style={{ fontWeight: "550", marginTop: "8px" }}>
                              {this.state.error.MobileNumber}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="formInput">
                          <div className="visitor-form-top-field-text">
                            <p
                              style={{
                                marginBottom: "0px",
                                color: "#282e3f",
                                fontWeight: "600",
                                fontSize: "15px",
                              }}
                            >
                              Email*
                            </p>
                            <span
                              style={{
                                marginBottom: "0px",
                                color: "#282e3f",
                              }}
                            >
                              البريد الالكتروني المعتمد
                            </span>
                          </div>
                          <input
                            placeholder=""
                            value={this.state.email}
                            name="email"
                            /*    onChange={this.changeHandler} */
                            className="form-control"
                            type="text"
                          />

                          {this.state.error.email && (
                            <p style={{ fontWeight: "550", marginTop: "8px" }}>
                              {this.state.error.email}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="formInput">
                          <div className="visitor-form-top-field-text">
                            <p
                              style={{
                                marginBottom: "0px",
                                color: "#282e3f",
                                fontWeight: "600",
                                fontSize: "15px",
                              }}
                            >
                              UAE Address*
                            </p>
                            <span
                              style={{
                                marginBottom: "0px",
                                color: "#282e3f",
                              }}
                            >
                              العنوان الإماراتي
                            </span>
                          </div>
                          <input
                            dir={this.state.typingArabic ? "rtl" : "ltr"}
                            placeholder=""
                            value={this.state.UAEAddress}
                            name="UAEAddress"
                            /*    onChange={this.changeHandler} */
                            className="form-control"
                            type="text"
                          />

                          {this.state.error.UAEAddress && (
                            <p style={{ fontWeight: "550", marginTop: "8px" }}>
                              {this.state.error.UAEAddress}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Emirates ID*
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          هويه الإمارات
                        </span>
                      </div>
                      <input
                        dir={this.state.typingArabic ? "rtl" : "ltr"}
                        placeholder=""
                        value={this.state.emiratesId}
                        name="emiratesId"
                        onChange={this.changeHandler}
                        className="form-control"
                        type="text"
                      />

                      {this.state.error.emiratesId && (
                        <p style={{ fontWeight: "550", marginTop: "8px" }}>
                          {this.state.error.emiratesId}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Nationality*
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          الجنسية
                        </span>
                      </div>
                      <input
                        className="Nationality1-list"
                        value={this.state.Nationality}
                        onChange={this.changeHandler}
                        name="Nationality"
                        placeholder="search..."
                        autoComplete="on"
                        style={{ width: "100%" }}
                        list="Nationality1"
                        name="Nationality"
                      />
                      <datalist id="Nationality1">
                        {/*      <option selected disabled>Nationality</option> */}
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Åland Islands">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">
                          Antigua and Barbuda
                        </option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegovina">
                          Bosnia and Herzegovina
                        </option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">
                          British Indian Ocean Territory
                        </option>
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">
                          Christmas Island
                        </option>
                        <option value="Cocos (Keeling) Islands">
                          Cocos (Keeling) Islands
                        </option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, The Democratic Republic of The">
                          Congo, The Democratic Republic of The
                        </option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote D'ivoire">Cote D'ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">
                          Falkland Islands (Malvinas)
                        </option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">
                          French Polynesia
                        </option>
                        <option value="French Southern Territories">
                          French Southern Territories
                        </option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-bissau">Guinea-bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">
                          Heard Island and Mcdonald Islands
                        </option>
                        <option value="Holy See (Vatican City State)">
                          Holy See (Vatican City State)
                        </option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">
                          Iran, Islamic Republic of
                        </option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">
                          Korea, Democratic People's Republic of
                        </option>
                        <option value="Korea, Republic of">
                          Korea, Republic of
                        </option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">
                          Lao People's Democratic Republic
                        </option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">
                          Libyan Arab Jamahiriya
                        </option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia, The Former Yugoslav Republic of">
                          Macedonia, The Former Yugoslav Republic of
                        </option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">
                          Micronesia, Federated States of
                        </option>
                        <option value="Moldova, Republic of">
                          Moldova, Republic of
                        </option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">
                          Netherlands Antilles
                        </option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">
                          Northern Mariana Islands
                        </option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">
                          Palestinian Territory, Occupied
                        </option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">
                          Russian Federation
                        </option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Helena">Saint Helena</option>
                        <option value="Saint Kitts and Nevis">
                          Saint Kitts and Nevis
                        </option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Pierre and Miquelon">
                          Saint Pierre and Miquelon
                        </option>
                        <option value="Saint Vincent and The Grenadines">
                          Saint Vincent and The Grenadines
                        </option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">
                          Sao Tome and Principe
                        </option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia and The South Sandwich Islands">
                          South Georgia and The South Sandwich Islands
                        </option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">
                          Svalbard and Jan Mayen
                        </option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">
                          Syrian Arab Republic
                        </option>
                        <option value="Taiwan, Province of China">
                          Taiwan, Province of China
                        </option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">
                          Tanzania, United Republic of
                        </option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-leste">Timor-leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">
                          Trinidad and Tobago
                        </option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">
                          Turks and Caicos Islands
                        </option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">
                          United Arab Emirates
                        </option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">
                          United States Minor Outlying Islands
                        </option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Virgin Islands, British">
                          Virgin Islands, British
                        </option>
                        <option value="Virgin Islands, U.S.">
                          Virgin Islands, U.S.
                        </option>
                        <option value="Wallis and Futuna">
                          Wallis and Futuna
                        </option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </datalist>
                      {this.state.error.Nationality && (
                        <p>{this.state.error.Nationality}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Company Name
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          اسم الشركة
                        </span>
                      </div>
                      <input
                        placeholder=""
                        ref={(input) => (this.Occupation = input)}
                        className="form-control"
                        type="text"
                        dir={this.state.typingArabic ? "rtl" : "ltr"}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                        personal/company Manager
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                         الشخص بصفتة الشخصية/ مدير الشركة
                        </span>
                      </div>
                      <input
                        dir={this.state.typingArabic ? "rtl" : "ltr"}
                        placeholder=""
                        /*      value={this.state.Employer} */
                        name="Employer"
                        /*   onChange={this.changeHandler} */
                        ref={(input) => (this.Employer = input)}
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Permanent Address*
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          العنوان الثابت
                        </span>
                      </div>
                      <input
                        dir={this.state.typingArabic ? "rtl" : "ltr"}
                        placeholder=""
                        value={this.state.PermanentAddress}
                        name="PermanentAddress"
                        onChange={this.changeHandler}
                        ref={(input) => (this.PermanentAddress = input)}
                        className="form-control"
                        type="text"
                      />
                      {this.state.error.PermanentAddress && (
                        <p>{this.state.error.PermanentAddress}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Home Tel
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          هاتف المنزل
                        </span>
                      </div>
                      <input
                        placeholder=""
                        /*   value={this.state.HomeTel} */
                        name="HomeTel"
                        /*     onChange={this.changeHandler} */
                        ref={(input) => (this.HomeTel = input)}
                        className="form-control"
                        type="text"
                      />

                      {/*    {this.state.error.HomeTel && (
                  <p style={{ fontWeight: "550", marginTop: "8px" }}>
                    {this.state.error.HomeTel}
                  </p>
                )} */}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Office Tel
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          هاتف المكتب
                        </span>
                      </div>
                      <input
                        placeholder=""
                        /*   value={this.state.OfficeTel} */
                        name="OfficeTel"
                        /*    onChange={this.changeHandler} */
                        ref={(input) => (this.OfficeTel = input)}
                        className="form-control"
                        type="text"
                      />

                      {/*   {this.state.error.OfficeTel && (
                  <p style={{ fontWeight: "550", marginTop: "8px" }}>
                    {this.state.error.OfficeTel}
                  </p>
                )} */}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Fax
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          فاكس
                        </span>
                      </div>
                      <input
                        placeholder=""
                        /*      value={this.state.Fax} */
                        name="Fax"
                        /*   onChange={this.changeHandler} */
                        ref={(input) => (this.Fax = input)}
                        className="form-control"
                        type="text"
                      />

                      {/*    {this.state.error.Fax && (
                  <p style={{ fontWeight: "550", marginTop: "8px" }}>
                    {this.state.error.Fax}
                  </p>
                )} */}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="upload-photo-area">
                      <div>
                        {/* <embed width="100" height="60" name="plugin" src={this.state.preview1[0]}  /> */}
                        <center>
                          {this.state.preview.length > 0 ? (
                            <embed
                              width="150"
                              height="90"
                              name="plugin"
                              src={this.state.preview}
                            />
                          ) : (
                            <Icon
                              style={{ marginTop: "30px" }}
                              name="id card"
                              size="huge"
                            />
                          )}

                          <div className="image-upload">
                            <label for="file-input2">
                              <p
                                style={{
                                  fontSize: "12px",
                                  cursor: "pointer",
                                  fontWeight: "550",
                                }}
                              >
                                Select Emirates ID
                              </p>
                            </label>

                            <input
                              id="file-input2"
                              name="photo15"
                              type="file"
                              accept=".png,.jpg,.jpeg,.pdf"
                              onChange={this.handleFileChange}
                            />
                          </div>
                        </center>
                      </div>
                    </div>
                    {this.state.submitFlag ? (
                      this.state.emiratePhoto.length > 0 ? (
                        ""
                      ) : (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            fontWeight: "550",
                          }}
                        >
                          Must select the EmiratesId
                        </p>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="upload-photo-area">
                      <div>
                        <center>
                          {this.state.preview1.length > 0 ? (
                            <embed
                              width="150"
                              height="90"
                              name="plugin"
                              src={this.state.preview1}
                            />
                          ) : (
                            <Icon
                              style={{ marginTop: "30px" }}
                              name="id card outline"
                              size="huge"
                            />
                          )}

                          <div className="image-upload">
                            <label for="file-input4">
                              <p
                                style={{
                                  fontSize: "12px",
                                  cursor: "pointer",
                                  fontWeight: "550",
                                }}
                              >
                                Select Passport Copy
                              </p>
                            </label>

                            <input
                              id="file-input4"
                              name="photo15"
                              type="file"
                              accept=".png,.jpg,.jpeg,.pdf"
                              onChange={this.handleFileChange1}
                            />
                          </div>
                        </center>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="upload-photo-area">
                      <div>
                        <center>
                          {this.state.preview2.length > 0 ? (
                            <embed
                              width="150"
                              height="90"
                              name="plugin"
                              src={this.state.preview2}
                            />
                          ) : (
                            <Icon
                              style={{ marginTop: "30px" }}
                              name="upload"
                              size="huge"
                            />
                          )}

                          <div className="image-upload">
                            <label for="file-input5">
                              <p
                                style={{
                                  fontSize: "12px",
                                  cursor: "pointer",
                                  fontWeight: "550",
                                }}
                              >
                                Select Visa
                              </p>
                            </label>

                            <input
                              id="file-input5"
                              name="photo15"
                              type="file"
                              accept=".png,.jpg,.jpeg,.pdf"
                              onChange={this.handleFileChange2}
                            />
                          </div>
                        </center>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <h3
                      style={{
                        fontWeight: "550",
                        marginTop: "20px",
                        marginBottom: "15px",
                      }}
                    >
                      {" "}
                      File Detail:
                      <span
                        className="arabic-style-font-visitor"
                        style={{ marginLeft: "20px", fontSize: "24px" }}
                      >
                        تفاصيل الملف
                      </span>
                    </h3>
                  </div>
                  <div className="col-12 col-lg-6 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          File No*
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          رقم الملف
                        </span>
                      </div>
                      <select
                        value={this.state.fileNumber}
                        className="form-control"
                        onChange={this.changeHandler}
                        /*   required */
                        name="fileNumber"
                      >
                        {this.state.options1.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.level}
                          </option>
                        ))}
                      </select>
                      {this.state.error.fileNumber && (
                        <p>{this.state.error.fileNumber}</p>
                      )}
                      {this.state.fileError ? (
                        <p style={{ color: "red" }}>
                          You don't have any Open a File{" "}
                          <Link
                            to="/createFile"
                            onClick={this.handleOpenFile}
                            style={{
                              color: "white",
                              backgroundColor: "#666666",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                              borderRadius: "10px",
                            }}
                          >
                            Open File
                          </Link>{" "}
                        </p>
                      ) : (
                        ""
                      )}
                      {this.state.fileLoading ? (
                        <p style={{ color: "red" }}>Loading Please wait... </p>
                      ) : (
                        ""
                      )}
                      <p style={{ color: "red" }}>{this.state.fileError1} </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Client Type
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          نوع العميل
                        </span>
                      </div>
                      <input
                        type="text"
                        dir={this.state.typingArabic ? "rtl" : "ltr"}
                        value={this.state.clientType}
                        className="form-control"
                        onChange={this.changeHandler}
                        /*   required */
                        name="clientType"
                      />
                      {this.state.error.clientType && (
                        <p>{this.state.error.clientType}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Lawyer
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          محامي
                        </span>
                      </div>
                      <input
                        placeholder=""
                        className="form-control"
                        type="text"
                        dir={this.state.typingArabic ? "rtl" : "ltr"}
                        ref={(input) => (this.lawyerName = input)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <h3
                      style={{
                        fontWeight: "550",
                        marginTop: "20px",
                        marginBottom: "15px",
                      }}
                    >
                      {" "}
                      Case Detail:
                      <span
                        className="arabic-style-font-visitor"
                        style={{ marginLeft: "20px", fontSize: "24px" }}
                      >
                        تفاصيل القضية
                      </span>
                    </h3>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Stage
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          المسرح
                        </span>
                      </div>
                      <input
                        dir={this.state.typingArabic ? "rtl" : "ltr"}
                        value={this.state.stage}
                        className="form-control"
                        onChange={this.changeHandler}
                        name="stage"
                      />
                      {this.state.error.stage && (
                        <p>{this.state.error.stage}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Case Type
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          نوع القضية
                        </span>
                      </div>
                      <select
                        value={this.state.caseType}
                        className="form-control"
                        onChange={this.changeHandler}
                        name="caseType"
                      >
                        {options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.level}
                          </option>
                        ))}
                      </select>
                      {this.state.error.caseType && (
                        <p>{this.state.error.caseType}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          POA Type
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          وكالة دورية
                        </span>
                      </div>
                      <input
                        className="form-control"
                        ref={(input) => (this.poaType = input)}
                      />
                    </div>
                  </div>
                 
                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Accusation
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          الاتهام
                        </span>
                      </div>
                      <input
                        placeholder=""
                        className="form-control"
                        type="text"
                        dir={this.state.typingArabic ? "rtl" : "ltr"}
                        ref={(input) => (this.accusation = input)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Opponent Name
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          اسم الخصم
                        </span>
                      </div>
                      <input
                        placeholder=""
                        className="form-control"
                        type="text"
                        dir={this.state.typingArabic ? "rtl" : "ltr"}
                        ref={(input) => (this.oppenentName = input)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                         Opponent Nationality
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                         جنسية الخصم
                        </span>
                      </div>
                      <input
                        className="Nationality1-list"
                        name="Nationality"
                        placeholder="search..."
                        autoComplete="on"
                        style={{ width: "100%" }}
                        list="Nationality1"
                        name="Nationality"
                        ref={(input) => (this.opponentNationality = input)}
                      />
                      <datalist id="Nationality1">
                        {/*      <option selected disabled>Nationality</option> */}
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Åland Islands">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">
                          Antigua and Barbuda
                        </option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegovina">
                          Bosnia and Herzegovina
                        </option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">
                          British Indian Ocean Territory
                        </option>
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">
                          Christmas Island
                        </option>
                        <option value="Cocos (Keeling) Islands">
                          Cocos (Keeling) Islands
                        </option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, The Democratic Republic of The">
                          Congo, The Democratic Republic of The
                        </option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote D'ivoire">Cote D'ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">
                          Falkland Islands (Malvinas)
                        </option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">
                          French Polynesia
                        </option>
                        <option value="French Southern Territories">
                          French Southern Territories
                        </option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-bissau">Guinea-bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">
                          Heard Island and Mcdonald Islands
                        </option>
                        <option value="Holy See (Vatican City State)">
                          Holy See (Vatican City State)
                        </option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">
                          Iran, Islamic Republic of
                        </option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">
                          Korea, Democratic People's Republic of
                        </option>
                        <option value="Korea, Republic of">
                          Korea, Republic of
                        </option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">
                          Lao People's Democratic Republic
                        </option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">
                          Libyan Arab Jamahiriya
                        </option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia, The Former Yugoslav Republic of">
                          Macedonia, The Former Yugoslav Republic of
                        </option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">
                          Micronesia, Federated States of
                        </option>
                        <option value="Moldova, Republic of">
                          Moldova, Republic of
                        </option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">
                          Netherlands Antilles
                        </option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">
                          Northern Mariana Islands
                        </option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">
                          Palestinian Territory, Occupied
                        </option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">
                          Russian Federation
                        </option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Helena">Saint Helena</option>
                        <option value="Saint Kitts and Nevis">
                          Saint Kitts and Nevis
                        </option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Pierre and Miquelon">
                          Saint Pierre and Miquelon
                        </option>
                        <option value="Saint Vincent and The Grenadines">
                          Saint Vincent and The Grenadines
                        </option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">
                          Sao Tome and Principe
                        </option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia and The South Sandwich Islands">
                          South Georgia and The South Sandwich Islands
                        </option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">
                          Svalbard and Jan Mayen
                        </option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">
                          Syrian Arab Republic
                        </option>
                        <option value="Taiwan, Province of China">
                          Taiwan, Province of China
                        </option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">
                          Tanzania, United Republic of
                        </option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-leste">Timor-leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">
                          Trinidad and Tobago
                        </option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">
                          Turks and Caicos Islands
                        </option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">
                          United Arab Emirates
                        </option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">
                          United States Minor Outlying Islands
                        </option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Virgin Islands, British">
                          Virgin Islands, British
                        </option>
                        <option value="Virgin Islands, U.S.">
                          Virgin Islands, U.S.
                        </option>
                        <option value="Wallis and Futuna">
                          Wallis and Futuna
                        </option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </datalist>
                      {this.state.error.Nationality && (
                        <p>{this.state.error.Nationality}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                         Opponent Phone No
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          رقم هاتف الخصم
                        </span>
                      </div>
                      <input
                        placeholder=""
                        className="form-control"
                        type="text"
                        dir={this.state.typingArabic ? "rtl" : "ltr"}
                        ref={(input) => (this.opponentPhoneNumber = input)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                         Opponent Email
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          البريد الإلكتروني للخصم
                        </span>
                      </div>
                      <input
                        placeholder=""
                        className="form-control"
                        type="text"
                        dir={this.state.typingArabic ? "rtl" : "ltr"}
                        ref={(input) => (this.opponentEmail = input)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                         Opponent Address
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          عنوان الخصم

                        </span>
                      </div>
                      <input
                        placeholder=""
                        className="form-control"
                        type="text"
                        dir={this.state.typingArabic ? "rtl" : "ltr"}
                        ref={(input) => (this.opponentAddress = input)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Case Description
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          وصف حالة
                        </span>
                      </div>
                      <textarea
                        dir={this.state.typingArabic ? "rtl" : "ltr"}
                        className="form-control"
                        value={this.state.description}
                        onChange={this.changeHandler}
                        placeholder="Type your case detail*"
                        name="description"
                      />
                      {this.state.error.description && (
                        <p>{this.state.error.description}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="upload-photo-area">
                      <div>
                        <center>
                          {this.state.preview3.length > 0 ? (
                            <embed
                              width="150"
                              height="90"
                              name="plugin"
                              src={this.state.preview3}
                            />
                          ) : (
                            <Icon
                              style={{ marginTop: "30px" }}
                              name="file alternate outline"
                              size="huge"
                            />
                          )}

                          <div className="image-upload">
                            <label for="file-input67">
                              <p
                                style={{
                                  fontSize: "12px",
                                  cursor: "pointer",
                                  fontWeight: "550",
                                }}
                              >
                                Upload POA Copy
                              </p>
                            </label>

                            <input
                              id="file-input67"
                              name="photo15"
                              type="file"
                              accept=".png,.jpg,.jpeg,.pdf"
                              onChange={this.handleFileChange3}
                            />
                          </div>
                        </center>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="upload-photo-area">
                      <div>
                        <center>
                          {this.state.preview4.length > 0 ? (
                            <embed
                              width="150"
                              height="90"
                              name="plugin"
                              src={this.state.preview4}
                            />
                          ) : (
                            <Icon
                              style={{ marginTop: "30px" }}
                              name="law"
                              size="huge"
                            />
                          )}

                          <div className="image-upload">
                            <label for="file-input47">
                              <p
                                style={{
                                  fontSize: "12px",
                                  cursor: "pointer",
                                  fontWeight: "550",
                                }}
                              >
                                Upload Case Evidance
                              </p>
                            </label>

                            <input
                              id="file-input47"
                              name="photo15"
                              type="file"
                              accept=".png,.jpg,.jpeg"
                              onChange={this.handleFileChange4}
                            />
                          </div>
                        </center>
                      </div>
                    </div>
                  </div>

                  <div className="col-12" style={{ marginTop: "20px" }}>
                    <div
                      className="checkbow-style-areafile"
                      style={{ color: "#666666" }}
                    >
                      <span>
                        {" "}
                        <input
                          class="styled-checkbox"
                          name="termsConditions"
                          onChange={this.handleConditions}
                          id="styled-checkbox-term"
                          type="checkbox"
                          value="valueterm"
                        />
                        <label for="styled-checkbox-term"></label>
                      </span>{" "}
                      <p
                        style={{
                          fontSize: "15px",
                          marginLeft: "10px",
                          fontWeight: "550",
                          color: "#282e3f",
                        }}
                      >
                        1. Kindly retain the original computer generated receipt
                        for any upcoming claims. If original receipt is lost, no
                        claims or reprint of the receipt will be entertained.
                        <br />
                        2. Claims or objections to be made in writing within 10
                        days from the date of the invoice issued.
                        <br />
                        3. If no claims or objections are made within the
                        previously mentioned period in clause, then it shall be
                        deemed as an approval of consumer in relation to the
                        provided transaction and their values.
                        <br />
                        4. Documents for the transactions done to be forwarded
                        along with the original invoice.
                        <br />
                        5. The maximum liability in the event of any claims or
                        objections is limited to the value of the claimed
                        transactions, which is/are mentioned in the invoice.
                        <br />
                        6. BADER HAMAD AL ZAABI ADVOCATES & LEGAL CONSULTANTS
                        reserve the right to review and aimed the above terms
                        from time as it judges fit, and as per the applicable
                        laws.
                        <br />{" "}
                      </p>
                    </div>
                    {this.state.termsSubmitFlag ? (
                      this.state.termsFlag ? (
                        <p
                          style={{
                            fontWeight: "550",
                            marginTop: "8px",
                            color: "red",
                          }}
                        >
                          You must accept the terms & Conditions
                        </p>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}

                    <p
                      style={{
                        fontWeight: "550",
                        marginTop: "8px",
                        color: "red",
                      }}
                    >
                      {this.state.errMessage}
                    </p>

                    {/* 
<p style={{textAlign:'right',fontWeight:'550',color:'#282e3f'}}>في حال عدم دفع الأقساط المتفق عليها و الرسوم بالعقد أو عدم تزويد المكتب بكافة المستندات المطلوبة و رسوم الخبرة وكافة الرسوم الإدارية يحق للمكتب عدم الحضور بالدعوى دون أد¦ مسؤولية مع حفظ
حق المكتب في المطالبة بباقي الأتعاب المتفق عليها</p>        
              
                      
            */}
                  </div>

                  <div className="col-12" style={{ marginTop: "0px" }}>
                    <div className="signon-bottom-area1">
                     
                      <div></div>
                      <div className="customer-file-loader-with-button">
                        {" "}
                        <span style={{ width: "50px", height: "50px" }}>
                          {this.state.loaderFlag ? (
                            <div
                              style={{ marginRight: "10px" }}
                              className="loader"
                            ></div>
                          ) : (
                            ""
                          )}
                        </span>{" "}
                        <button
                          style={{ display: "inline" }}
                          disabled={this.state.buttonFlag}
                          type="submit"
                        >
                          submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal
                  show={this.state.show}
                  onHide={this.handleClose}
                  size="sm"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="heading-modal">
                      <img
                        style={{ width: "30px" }}
                        src={require("../../images/about/man-01.png")}
                      />{" "}
                      Case Open
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div
                      className="sent-message-area"
                      style={{ paddingTop: "20px", paddingBottom: "20px" }}
                    >
                      <center>
                        <p>
                          <i
                            style={{ fontSize: "120px" }}
                            class="fa fa fa-check-circle-o"
                          ></i>
                        </p>
                        <h2>thank you!</h2>
                        <p>
                          Congratulations the Case against this customer is
                          successfully Opened!
                        </p>
                        <button
                          onClick={this.handleClose}
                          className="sent-button-des"
                        >
                          OK
                        </button>
                      </center>
                    </div>
                  </Modal.Body>
                </Modal>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    openCAseReducer: state.CustomerCaseForm1.openCAseReducer,
    getUsersReducer: state.CustomerCaseForm1.getUsersReducer,
    getUserCreateInvoceFilesReducer:
      state.CustomerCaseForm1.getUserCreateInvoceFilesReducer,
  };
};
export default compose(
  withReducer("CustomerCaseForm1", reducer),
  connect(mapStateToProps),
  withRouter
)(CustomerCaseForm);
