import {combineReducers} from 'redux';

import {allCasesCounterReducer} from './caseCounterRed'

import {fileSearchReducer} from './fileSearch'
import {allCasesReducer } from './allCustomerCasesRed';
const reducer = combineReducers({
   allCasesReducer,fileSearchReducer,allCasesCounterReducer 
    });
    
    export default reducer;
