import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const ALL_CUSTOMER_CASES_COUNTERT = "ALL_CUSTOMER_CASES_COUNTERT";
export const ALL_CUSTOMER_CASES_COUNTERT_LOADING =
"ALL_CUSTOMER_CASES_COUNTERT_LOADING";
export const ALL_CUSTOMER_CASES_COUNTERT_LOADING_FAILED =
"ALL_CUSTOMER_CASES_COUNTERT_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const getAllCustomerCasesCounterLoading = ms => ({
    type: ALL_CUSTOMER_CASES_COUNTERT_LOADING,
    payload: ms
    });
    
    export const getAllCustomerCasesCounterLoadingFailed = ms => ({
    type: ALL_CUSTOMER_CASES_COUNTERT_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function getAllCustomerCasesCounter(obj) {
    const  request = axios.get(
            `https://prod-legalemirates.wowets.com/fileService/receptionistRoutes/api/v1/cases/getCountOfCases`, {
                headers: { 'Authorization': 'bearer ' +localStorage.receptionistToken}
              });
        
 
    
    return dispatch => {

    dispatch(getAllCustomerCasesCounterLoading());
    request
    .then(response => {   
  
    if (response.status == 200 || response.status == 201) {
     console.log(response,"response getAllCustomerCasesCounter")
    dispatch({
    type: ALL_CUSTOMER_CASES_COUNTERT,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        getAllCustomerCasesCounterLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(getAllCustomerCasesCounterLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(getAllCustomerCasesCounterLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(getAllCustomerCasesCounterLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


