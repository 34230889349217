import React, { Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import FooterArea from '../../../components/FooterArea'

import AddSessionArea from '../../../components/addSession'
import './style.scss'
const AddSessionAreaPage = () => {

    return (
        <Fragment>
            
           <AddSessionArea
           className="SignInArea"
            />
            <FooterArea/>
        </Fragment>
    )
}
export default AddSessionAreaPage
/* AddSessionArea */